import axios from "@/http/axios";

const state = {
  allAssets: null,
};

const getters = {
  getAllAssets: (state) => {
    return state.allAssets;
  },
};

const mutations = {
  SAVE_ALL_ASSETS_SUCCESS: (state, { data }) => {
    if (!data) return;
    state.allAssets = data;
  },
};

const actions = {
  async fetchAllAssets({ commit }) {
    const response = await axios.get("/api/assets");
    const groupToAssets = response.data.reduce(
      (obj, item) => (
        obj[item.group] ? null : (obj[item.group] = []), obj[item.group].push(item), obj
      ),
      {}
    );
    commit("SAVE_ALL_ASSETS_SUCCESS", {
      data: Object.entries(groupToAssets).map((x) => ({
        assetGroup: x[0],
        assets: x[1],
      })),
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
