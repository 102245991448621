import "firebase/compat/firestore";

import firebase from "firebase/compat/app";
import { firestoreAction } from "vuexfire";

const state = {
  platformSettings: undefined,
  platformSettingsLangs: undefined,
};

const getters = {
  isBindingReady: (state) => {
    return (
      state.platformSettings != null &&
      state.platformSettingsLangs != null &&
      state.platformSettingsLangs.length > 0
    );
  },
  getCompliance: (state) => (lang) => {
    return (
      state.platformSettingsLangs?.find(
        (platformSettingsLang) => platformSettingsLang.id == lang
      ) ?? {}
    ).compliance;
  },
  getIam: (state) => {
    return state.platformSettings?.iam;
  },
  getPlatformSettingsLangs: (state) => {
    return state.platformSettingsLangs;
  },
};

const mutations = {};

const actions = {
  bindPlatformSettings: firestoreAction(({ bindFirestoreRef, state }) => {
    // Binding an already bound state is expensive, likely
    // because of an internal implementation that unbinds first
    // and then rebinds. It's why we avoid rebinding if it is
    // already bound.
    if (state.platformSettings != null) {
      return Promise.resolve();
    }

    return bindFirestoreRef(
      "platformSettings",
      firebase.firestore().collection("settings").doc("platform")
    );
  }),

  bindPlatformSettingsLangs: firestoreAction(({ bindFirestoreRef, state }) => {
    // Binding an already bound state is expensive, likely
    // because of an internal implementation that unbinds first
    // and then rebinds. It's why we avoid rebinding if it is
    // already bound.
    if (state.platformSettingsLangs != null) {
      return Promise.resolve();
    }

    return bindFirestoreRef(
      "platformSettingsLangs",
      firebase.firestore().collection("settings").doc("platform").collection("langs")
    );
  }),
  updateCompliance: firestoreAction((_, { newCompliance, lang }) => {
    return firebase
      .firestore()
      .collection("settings")
      .doc("platform")
      .collection("langs")
      .doc(lang)
      .set({ compliance: newCompliance }, { merge: true });
  }),
  updateIam: firestoreAction((_, { newIam }) => {
    return firebase
      .firestore()
      .collection("settings")
      .doc("platform")
      .set({ iam: newIam }, { merge: true });
  }),
};

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};
