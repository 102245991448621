// List of all cards that can be used in an analysis page for generic products.
export const GenericProductCards = Object.freeze({
  cumulativeReturns: "cumulativeReturns",
  performanceStats: "performanceStats",
  outperformance: "outperformance",
  volatility: "volatility",
  drawdowns: "drawdowns",
  cumulativeWeightedReturns: "cumulativeWeightedReturns",
  returnProfile: "returnProfile",
  beta: "beta",
  riskAdjustedPerf: "riskAdjustedPerf",
  yearlyCumulativeReturns: "yearlyCumulativeReturns",
});
