import "firebase/compat/firestore";

import firebase from "firebase/compat/app";
import { firestoreAction } from "vuexfire";

import axios from "@/http/axios";

const state = {
  users: null,
};

const getters = {
  getUsers: (state) => {
    return state.users;
  },
  getUsersFollowingProduct: (state) => (productId) => {
    if (state.users == null) {
      return state.users;
    }

    return state.users.filter((user) =>
      user.followingProducts?.some((obj) => obj.productId === productId)
    );
  },
};

const mutations = {};

const actions = {
  bindUsers: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef("users", firebase.firestore().collection("users"));
  }),
  async removeSelfRegisteredFollower(_, { productId, email }) {
    const matchingUsers = await firebase
      .firestore()
      .collection("users")
      .where("email", "==", email)
      .get();
    if (matchingUsers.docs.length == 0) {
      throw new Error(`No user with given email address ('${email}').`);
    } else if (matchingUsers.docs.length > 1) {
      throw new Error(`Multiple users with given email address ('${email}').`);
    }
    const userRef = matchingUsers.docs[0].ref;
    const userDoc = await userRef.get();
    await axios.post("/api/users/remove-follower", {
      uid: firebase.auth().currentUser.uid,
      follower_uid: userDoc.id,
      product_id: productId,
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
