/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import "firebase/compat/auth";
import "firebase/compat/firestore";

import { AuthErrorCodes } from "firebase/auth";
import firebase from "firebase/compat/app";

import { EMAIL_AND_PWD, LOGIN_EVENT, STATUS_WARNING } from "@/assets/constants/analytics";
import { i18n } from "@/main.js";

export default {
  async loginAttempt({ dispatch }, userDetails) {
    if (!userDetails.checkboxRememberMe) {
      // Change firebase Persistence
      try {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
      } catch (error) {
        throw {
          errorToDisplay: i18n.t("pages.auth.errorInternal"),
          errorToLog: error.message || error,
          error,
          withRedirectButton: "contact-us",
        };
      }
    }

    const userCredential = await dispatch("loginWithEmailAndPassword", userDetails);
    return userCredential;
  },
  async loginWithEmailAndPassword({ getters, dispatch }, userDetails) {
    // As users haven't access to auth pages once logged in,
    // they shouldn't be able to log again while being logged.
    if (getters.isAuthenticated) {
      throw {
        errorToLog: "Already authenticated.",
      };
    }

    // Try to signin
    let userCredential;
    try {
      userCredential = await firebase
        .auth()
        .signInWithEmailAndPassword(userDetails.email, userDetails.password);
    } catch (error) {
      if (error.code == AuthErrorCodes.INVALID_LOGIN_CREDENTIALS) {
        throw {
          errorToDisplay: i18n.t("pages.auth.login.errorInvalidLoginCredentials"),
          errorToLog: "Invalid credentials",
        };
      } else {
        throw {
          errorToDisplay: i18n.t("pages.auth.errorInternal"),
          errorToLog: error.message || error,
          error,
          withRedirectButton: "contact-us",
        };
      }
    }

    if (!userCredential.user.emailVerified) {
      // If the email isn't verified, we stopped the logging.
      await dispatch("signOut");
      throw {
        errorToDisplay: i18n.t("pages.auth.login.errorNeedEmailVerification"),
        errorToLog: "Missing email verification",
        withRedirectButton: "reset-password",
      };
    } else {
      // If the user doesn't have a Firestore user it means it tries to log
      // in without being registered first, hence we stopped the process and
      // ask to register first.
      let isUserInFirestore;
      try {
        isUserInFirestore = await dispatch("isUserInFirestore", userCredential);
      } catch (error) {
        // We have a special case if at this point we couldn't get the firestore data. We
        // have seen, at least in one case (bcf), that after the sign in something is making
        // all calls to firestore result in a 403, and we get this "failed to get doc because
        // offline" error message.
        // As it might likely be linked to proxies rules, we decided to simply reload the page,
        // because going on a fresh page while being signed in doesn't end up with failing
        // calls to firestore. When reloading the page, if the user is correctly signed in, it
        // will be redirected to the home page.
        if (
          error.errorToLog ===
          "Couldn't get information from Firestore. Failed to get document because the client is offline."
        ) {
          firebase.analytics().logEvent(LOGIN_EVENT, {
            status: STATUS_WARNING,
            sign_in_type: EMAIL_AND_PWD,
            message: "Can't connect to firestore after sign in. Force page reload.",
          });
          location.reload();

          // Important to stop any code processing after we started the location.reload, because
          // it doesn't kill ongoing code, and hence if we don't add this "return", the code
          // will go the next part where we delete the user if isUserInFirestore is still
          // undefined.
          return;
        } else {
          throw error;
        }
      }

      if (isUserInFirestore) {
        return userCredential;
      } else {
        await userCredential.user.delete();
        throw {
          errorToDisplay: i18n.t("pages.auth.login.errorAccountNotExist"),
          errorToLog: "Try login without register with email and password (missing Firestore)",
          withRedirectButton: "register",
        };
      }
    }
  },
  // Custom login for headless browser sign in.
  async signInWithCustomToken({ getters }, token) {
    // A user shouldn't be able to log again while being logged in.
    if (getters.isAuthenticated) {
      throw new Error("Already authenticated");
    }

    await firebase.auth().signInWithCustomToken(token);
  },
  // Google Login
  async loginWithGoogle({ getters, dispatch }) {
    // As users haven't access to auth pages once logged in,
    // they shouldn't be able to log again while being logged.
    if (getters.isAuthenticated) {
      throw {
        errorToLog: "Already authenticated.",
      };
    }

    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    let userCredential;
    try {
      userCredential = await firebase.auth().signInWithPopup(provider);
    } catch (error) {
      // The only error that doesn't require a contact us button
      // is if the user close the popup instead of logging through it.
      if (error.code == "auth/popup-closed-by-user") {
        throw {
          errorToLog: "Google popup closed",
        };
      } else {
        throw {
          errorToDisplay: i18n.t("pages.auth.errorInternal"),
          errorToLog: error.message || error,
          error,
          withRedirectButton: "contact-us",
        };
      }
    }

    // At this stage, if we have more than 1 provider, it means we are
    // already logged in through password. We don't want to authorize
    // this, hence we unlink the Google provider and return an error message.

    // Note: if the user hasn't verified its email after registering with
    // a password, then Google provider will *overwrite* the password
    // provider. It is an expected behavior and complex to check.
    // Here we will assume that if the user wanted to log with password, and
    // not Google while available during registration, it was somehow a misclick.
    // https://stackoverflow.com/questions/37396194/firebase-logging-in-with-new-provider-google-removes-previous-provider-passwo
    if (userCredential.user.providerData.length > 1) {
      // Note that we don't need a catch here because the only error
      // that can arise from unlink is if the providerId is wrong,
      // and we defined it from the official provider just above.
      await userCredential.user.unlink(provider.providerId);
      // Bad case, the user is already registered with this email with a
      // password.
      try {
        await dispatch("signOut");
      } finally {
        throw {
          errorToDisplay: i18n.t("pages.auth.errorAlreadyEmailAndPasswordRegister"),
          errorToLog: "Try login with Google while having register with email and password",
          withRedirectButton: false,
        };
      }
    } else {
      // If the user doesn't have a Firestore user it means it tries to log
      // in without being registered first, hence we stopped the process and
      // ask to register first.
      const isUserInFirestore = await dispatch("isUserInFirestore", userCredential);
      if (isUserInFirestore) {
        return userCredential;
      } else {
        try {
          await userCredential.user.delete();
        } finally {
          throw {
            errorToDisplay: i18n.t("pages.auth.login.errorAccountNotExist"),
            errorToLog: "Try login with Google without register with Google (missing Firestore)",
            withRedirectButton: "register",
          };
        }
      }
    }
  },
  // Microsoft Login
  async loginWithMicrosoft({ getters, dispatch }) {
    // As users haven't access to auth pages once logged in,
    // they shouldn't be able to log again while being logged.
    if (getters.isAuthenticated) {
      throw {
        errorToLog: "Already authenticated.",
      };
    }

    const provider = new firebase.auth.OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      prompt: "consent",
    });
    let userCredential;
    try {
      userCredential = await firebase.auth().signInWithPopup(provider);
    } catch (error) {
      // The only error that doesn't require a contact us button
      // is if the user close the popup instead of logging through it.
      if (error.code == "auth/popup-closed-by-user") {
        throw {
          errorToLog: "Google popup closed",
        };
      } else {
        throw {
          errorToDisplay: i18n.t("pages.auth.errorInternal"),
          errorToLog: error.message || error,
          error,
          withRedirectButton: "contact-us",
        };
      }
    }

    // At this stage, if we have more than 1 provider, it means we are
    // already logged in through password. We don't want to authorize
    // this, hence we unlink the Microsoft provider and return an error message.
    if (userCredential.user.providerData.length > 1) {
      // Note that we don't need a catch here because the only error
      // that can arise from unlink is if the providerId is wrong,
      // and we defined it from the official provider just above.
      await userCredential.user.unlink(provider.providerId);
      // Bad case, the user is already registered with this email with a
      // password.
      try {
        await dispatch("signOut");
      } finally {
        throw {
          errorToDisplay: i18n.t("pages.auth.errorAlreadyEmailAndPasswordRegister"),
          errorToLog: "Try login with Google while having register with email and password",
          withRedirectButton: false,
        };
      }
    } else {
      // If the user doesn't have a Firestore user it means it tries to log
      // in without being registered first, hence we stopped the process and
      // ask to register first.
      const isUserInFirestore = await dispatch("isUserInFirestore", userCredential);
      if (isUserInFirestore) {
        return userCredential;
      } else {
        try {
          await userCredential.user.delete();
        } finally {
          throw {
            errorToDisplay: i18n.t("pages.auth.login.errorAccountNotExist"),
            errorToLog:
              "Try login with Microsoft without register with Microsoft (missing Firestore)",
            withRedirectButton: "register",
          };
        }
      }
    }
  },
  async registerWithGoogle({ dispatch, getters }, userDetails) {
    // As users haven't access to auth pages once logged in,
    // they shouldn't be able to log again while being logged.
    if (getters.isAuthenticated) {
      throw {
        errorToLog: "Already authenticated.",
      };
    }

    const provider = new firebase.auth.GoogleAuthProvider();
    let userCredential;
    try {
      userCredential = await firebase.auth().signInWithPopup(provider);
    } catch (error) {
      if (firebase.auth().currentUser) {
        // If at this point we are still somewhat logged in, while still getting
        // an error, it means we have a partial user creation, that should lead
        // to a cleaning of the partially created account.
        try {
          await firebase.auth().currentUser.delete();
        } finally {
          throw {
            errorToDisplay: i18n.t("pages.auth.errorInternal"),
            errorToLog: "Partial user creation. " + (error.message || error),
            error,
            withRedirectButton: "contact-us",
          };
        }
      } else {
        // The only error that doesn't require a contact us button
        // is if the user close the popup instead of logging through it.
        if (error.code == "auth/popup-closed-by-user") {
          throw {
            errorToLog: "Google popup closed",
          };
        } else {
          throw {
            errorToDisplay: i18n.t("pages.auth.errorInternal"),
            errorToLog: error.message || error,
            error,
            withRedirectButton: "contact-us",
          };
        }
      }
    }

    // At this stage, if we have more than 1 provider, it means we are
    // already logged in through password. We don't want to authorize
    // this, hence we unlink the Google provider and return an error message.
    if (userCredential.user.providerData.length > 1) {
      // Note that we don't need a catch here because the only error
      // that can arise from unlink is if the providerId is wrong,
      // and we defined it from the official provider just above.
      await userCredential.user.unlink(provider.providerId);
      // Bad case, the user is already registered with this email with a
      // password.
      try {
        await dispatch("signOut");
      } finally {
        throw {
          errorToDisplay: i18n.t("pages.auth.errorAlreadyEmailAndPasswordRegister"),
          errorToLog:
            "Try register with Google while having register with another provider already",
          withRedirectButton: "login",
        };
      }
    } else {
      const isUserInFirestore = await dispatch("isUserInFirestore", userCredential);
      if (isUserInFirestore) {
        // Bad case, the user is already registered with this email.
        try {
          await dispatch("signOut");
        } finally {
          throw {
            errorToDisplay: i18n.t("pages.auth.register.errorAlreadyGoogleRegister"),
            errorToLog: "Try register with Google while having register with Google already",
            withRedirectButton: "login",
          };
        }
      } else {
        // Good case where the user tries to register for the first time
        // (or at least doesn't have a Firestore user yet).
        const userInfoToSave = {
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          email: userCredential.user.email,
          country: userDetails.country,
          investorProfile: userDetails.investorProfile,
          company: userDetails.company,
          telNumber: userDetails.telNumber ?? "",
          telNumberCountryCode: userDetails.telNumber ? userDetails.telNumberCountryCode : "",
        };
        // Create special document for this user in firestore.
        try {
          await dispatch(
            "userInfo/createUserInfo",
            {
              uid: userCredential.user.uid,
              userInfo: userInfoToSave,
            },
            { root: true }
          );
          return userCredential;
        } catch (error) {
          // If we get an error while trying to save in Firestore, we stop
          // and kill the registration, hence delete the newly created user
          // in Firebase.
          try {
            await userCredential.user.delete();
          } finally {
            throw {
              errorToDisplay: i18n.t("pages.auth.errorInternal"),
              errorToLog: "Couldn't create in userInfo in Firestore. " + (error.message || error),
              error,
              withRedirectButton: "contact-us",
            };
          }
        }
      }
    }
  },
  async registerWithMicrosoft({ dispatch, getters }, userDetails) {
    // As users haven't access to auth pages once logged in,
    // they shouldn't be able to log again while being logged.
    if (getters.isAuthenticated) {
      throw {
        errorToLog: "Already authenticated.",
      };
    }

    const provider = new firebase.auth.OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      prompt: "consent",
    });
    let userCredential;
    try {
      userCredential = await firebase.auth().signInWithPopup(provider);
    } catch (error) {
      if (firebase.auth().currentUser) {
        // If at this point we are still somewhat logged in, while still getting
        // an error, it means we have a partial user creation, that should lead
        // to a cleaning of the partially created account.
        try {
          await firebase.auth().currentUser.delete();
        } finally {
          throw {
            errorToDisplay: i18n.t("pages.auth.errorInternal"),
            errorToLog: "Partial user creation. " + (error.message || error),
            error,
            withRedirectButton: "contact-us",
          };
        }
      } else {
        // The only error that doesn't require a contact us button
        // is if the user close the popup instead of logging through it.
        if (error.code == "auth/popup-closed-by-user") {
          throw {
            errorToLog: "Microsoft popup closed",
          };
        } else {
          throw {
            errorToDisplay: i18n.t("pages.auth.errorInternal"),
            errorToLog: error.message || error,
            error,
            withRedirectButton: "contact-us",
          };
        }
      }
    }

    // At this stage, if we have more than 1 provider, it means we are
    // already logged in through password. We don't want to authorize
    // this, hence we unlink the Google provider and return an error message.
    if (userCredential.user.providerData.length > 1) {
      // Note that we don't need a catch here because the only error
      // that can arise from unlink is if the providerId is wrong,
      // and we defined it from the official provider just above.
      await userCredential.user.unlink(provider.providerId);
      // Bad case, the user is already registered with this email with a
      // password.
      try {
        await dispatch("signOut");
      } finally {
        throw {
          errorToDisplay: i18n.t("pages.auth.errorAlreadyEmailAndPasswordRegister"),
          errorToLog:
            "Try register with Microsoft while having register with another provider already",
          withRedirectButton: "login",
        };
      }
    } else {
      const isUserInFirestore = await dispatch("isUserInFirestore", userCredential);
      if (isUserInFirestore) {
        // Bad case, the user is already registered with this email.
        try {
          await dispatch("signOut");
        } finally {
          throw {
            errorToDisplay: i18n.t("pages.auth.register.errorAlreadyMicrosoftRegister"),
            errorToLog: "Try register with Microsoft while having register with Microsoft already",
            withRedirectButton: "login",
          };
        }
      } else {
        // Good case where the user tries to register for the first time
        // (or at least doesn't have a Firestore user yet).
        const userInfoToSave = {
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          email: userCredential.user.email,
          country: userDetails.country,
          investorProfile: userDetails.investorProfile,
          company: userDetails.company,
          telNumber: userDetails.telNumber ?? "",
          telNumberCountryCode: userDetails.telNumber ? userDetails.telNumberCountryCode : "",
        };
        // Create special document for this user in firestore.
        try {
          await dispatch(
            "userInfo/createUserInfo",
            {
              uid: userCredential.user.uid,
              userInfo: userInfoToSave,
            },
            { root: true }
          );

          return userCredential;
        } catch (error) {
          // If we get an error while trying to save in Firestore, we stop
          // and kill the registration, hence delete the newly created user
          // in Firebase.
          userCredential.user.delete().finally(() => {
            throw {
              errorToDisplay: i18n.t("pages.auth.errorInternal"),
              errorToLog: "Couldn't create in userInfo in Firestore. " + (error.message || error),
              error,
              withRedirectButton: "contact-us",
            };
          });
        }
      }
    }
  },
  async registerWithEmailAndPassword({ getters, dispatch }, userDetails) {
    // As users haven't access to auth pages once logged in,
    // they shouldn't be able to log again while being logged.
    if (getters.isAuthenticated) {
      throw {
        errorToLog: "Already authenticated.",
      };
    }

    // create user using firebase
    let userCredential;
    try {
      userCredential = await firebase
        .auth()
        .createUserWithEmailAndPassword(userDetails.email, userDetails.password);
    } catch (error) {
      if (firebase.auth().currentUser) {
        // If at this point we are still somewhat logged in, while still getting
        // an error, it means we have a partial user creation, that should lead
        // to a cleaning of the partially created account.
        try {
          await firebase.auth().currentUser.delete();
        } finally {
          throw {
            errorToDisplay: i18n.t("pages.auth.errorInternal"),
            errorToLog: "Partial user creation. " + (error.message || error),
            error: error,
            withRedirectButton: "contact-us",
          };
        }
      } else {
        // We don't want a contact us button if the user tries to register
        // with an email already used or if the password is too weak.
        if (error.code == "auth/email-already-in-use") {
          throw {
            errorToDisplay: i18n.t("pages.auth.register.errorAlreadyEmailAndPasswordRegister"),
            errorToLog:
              "Try register with email and password while having register with this email",
            withRedirectButton: "login",
          };
        } else if (error.code == "auth/weak-password") {
          throw {
            errorToDisplay: error.message || error,
            errorToLog: "Weak password",
            withRedirectButton: false,
          };
        } else {
          throw {
            errorToDisplay: i18n.t("pages.auth.errorInternal"),
            errorToLog: error.message || error,
            error,
            withRedirectButton: "contact-us",
          };
        }
      }
    }

    // Send verification email
    try {
      await userCredential.user.sendEmailVerification();
    } catch (error) {
      // Whatever the error, we want a contact us button.
      try {
        await userCredential.user.delete();
      } finally {
        throw {
          errorToDisplay: i18n.t("pages.auth.errorInternal"),
          errorToLog: error.message || error,
          error,
          withRedirectButton: "contact-us",
        };
      }
    }

    try {
      // Update profile in firebase auth.
      await dispatch("updateProfile", {
        user: userCredential.user,
        displayName: `${userDetails.firstName} ${userDetails.lastName}`,
        photoURL: userDetails.photoURL,
      });
    } catch (error) {
      // We couldn't update the profile for some reason.
      try {
        await userCredential.user.delete();
      } finally {
        throw error;
      }
    }

    const userInfoToSave = {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      country: userDetails.country,
      investorProfile: userDetails.investorProfile,
      company: userDetails.company,
      telNumber: userDetails.telNumber ?? "",
      telNumberCountryCode: userDetails.telNumber ? userDetails.telNumberCountryCode : "",
    };
    try {
      // Create special document for this user in firestore.
      await dispatch(
        "userInfo/createUserInfo",
        {
          uid: userCredential.user.uid,
          userInfo: userInfoToSave,
        },
        { root: true }
      );

      await dispatch("signOut");

      return userCredential;
    } catch (error) {
      // If we get an error while trying to save in Firestore, we stop
      // and kill the registration, hence delete the newly created user
      // in Firebase.
      try {
        await userCredential.user.delete();
      } finally {
        throw {
          errorToDisplay: i18n.t("pages.auth.errorInternal"),
          errorToLog: "Couldn't create in userInfo in Firestore. " + (error.message || error),
          error,
          withRedirectButton: "contact-us",
        };
      }
    }
  },

  setUser({ commit }, user) {
    if (!user) commit("SET_USER", null);
    const userToSave = {
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL ? user.photoURL : require("@/assets/images/profile/user.svg"),
      uid: user.uid,
    };
    commit("SET_USER", userToSave);
  },

  async signOut({ commit }) {
    await firebase.auth().signOut();
    commit("SET_USER", null);
  },

  async updateProfile(_, userDetails) {
    const newProfile = {};
    if (userDetails.displayName) newProfile.displayName = userDetails.displayName;
    if (userDetails.photoURL) newProfile.photoURL = userDetails.photoURL;
    try {
      await userDetails.user.updateProfile(newProfile);
    } catch (error) {
      // From doc, no possible error normally apart from connection error,
      // so if something happens, we want a contact us button.
      throw {
        errorToDisplay: i18n.t("pages.auth.errorInternal"),
        errorToLog: "Profile couldn't update. " + (error.message || error),
        error,
        withRedirectButton: "contact-us",
      };
    }
  },

  // Returns whether the user has indeed their information stored in Firestore or not.
  async isUserInFirestore(_, userCredential) {
    try {
      const doc = await firebase.firestore().collection("users").doc(userCredential.user.uid).get();
      return doc.exists;
    } catch (error) {
      // From doc, no possible error normally apart from connection error,
      // so if something happens, we want a contact us button.
      throw {
        errorToDisplay: i18n.t("pages.auth.errorInternal"),
        errorToLog: "Couldn't get information from Firestore. " + (error.message || error),
        error,
        withRedirectButton: "contact-us",
      };
    }
  },

  async setToken() {
    const user = firebase.auth().currentUser;
    if (!user) {
      return null;
    } else {
      const idToken = await user.getIdToken();
      return idToken;
    }
  },

  /**
   * Set the user info after the login, with the token and the roles
   */
  setLoginInfo({ dispatch }, user) {
    dispatch("setUser", user);
  },
  async deleteAccount({ commit }, { notify }) {
    const userEmail = firebase.auth().currentUser.email;
    await firebase.auth().currentUser.delete();
    // The delete operation signs out the user, but we still need to clean our store.
    commit("SET_USER", null);
    notify({
      time: 4000,
      title: "Success",
      text: "Your account was successfully deleted.",
      iconPack: "feather",
      icon: "icon-check",
      color: "success",
    });
    return userEmail;
  },
  resetPasswordForGuest(_, { email }) {
    return firebase.auth().sendPasswordResetEmail(email);
  },
  updatePassword(_, { currentPassword, newPassword, notify }) {
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
    // We need to reauthenticate the user for this kind of operations.
    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        user
          .updatePassword(newPassword)
          .then(() => {
            notify({
              title: "Success",
              text: "Your password was successfully updated.",
              color: "success",
              time: 5000,
            });
          })
          .catch((error) => {
            notify({
              title: "Error",
              text: "We couldn't change your password, please contact us using the contact form to fix this error!",
              color: "danger",
            });

            // We rethrow the error for sentry to catch it.
            throw error;
          });
      })
      .catch(() => {
        notify({
          title: "Error",
          text: "You provided the wrong password.",
          color: "danger",
        });
      });
  },
};
