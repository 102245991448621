import { Role } from "@/acl/roles";
import { FALGOM_PRODUCTS_DETAILS } from "@/assets/constants/products/falgom";

export default {
  orgName: "Falgom",
  showHomePageHeader: false,
  logoPath: require("@/assets/images/logo/falgom.svg"),
  navBarLogoStyle: "width: 100%; height: auto; max-height: 44px",
  allowedLoginMethods: ["Google"],
  hideLogoHomePage: true,
  background: {
    path: require("@/assets/images/background/falgom.jpg"),
  },
  products: FALGOM_PRODUCTS_DETAILS,
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en", "de"],
  followFeatureActivated: true,
  colorScheme: [
    "#3F698C",
    "#7993B0",
    "#BAC6D8",
    "#E1E6EF",
    "#BAD3E8",
    "#D1E1F0",
    "#EAF0F8",
    "#F6FBFD",
    // Same colors with less opacity
    "#3F698C7F",
    "#7993B07F",
    "#BAC6D87F",
    "#E1E6EF7F",
    "#BAD3E87F",
    "#D1E1F07F",
    "#EAF0F87F",
    "#F6FBFD7F",
  ],
};
