import deMessage from "./de/index.js";
import enMessage from "./en/index.js";
import frMessage from "./fr/index.js";
import ptMessage from "./pt/index.js";

export const messages = {
  en: enMessage,
  de: deMessage,
  fr: frMessage,
  pt: ptMessage,
};
