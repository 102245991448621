import { isEqual } from "lodash";
import moment from "moment";
import { extend } from "vee-validate";
import { confirmed, email, max, min, min_value, required } from "vee-validate/dist/rules";

import { i18n } from "@/main.js";

extend("email", {
  ...email,
  message: () => i18n.t("validations.email"),
});

extend("min_char", {
  ...min,
  message: (_, { length }) => i18n.t("validations.minChar", { length }),
});

extend("max_char", {
  ...max,
  message: (_, { length }) => i18n.t("validations.maxChar", { length }),
});

extend("required", {
  ...required,
  message: () => i18n.t("validations.required"),
});

// Note that we assume this rule is only used for
// passwords, with the confirm input being just below
// the password input, hence the message we define here.
extend("confirmed", {
  ...confirmed,
  message: () => i18n.t("validations.confirmed"),
});

extend("min_value", {
  ...min_value,
  message: (_, { min }) => i18n.t("validations.minValue", { min }),
});

extend("date_format", {
  params: ["format"],
  validate: (date, { format }) => {
    return moment(date, format, true).isValid();
  },
  message: () => i18n.t("validations.dateFormat"),
});

extend("after_date", {
  params: ["minDate", "nDaysApart", "format"],
  validate: (date, { minDate, nDaysApart, format }) => {
    // We only compare dates if they have valid formats.
    // Note that, normally, at this stage the 'date' is
    // valid as we check that first, but in case of we
    // do it again here.
    const dateMoment = moment(date, format, true);
    const minDateMoment = moment(minDate, format, true);
    if (!dateMoment.isValid() || !minDateMoment.isValid()) {
      return true;
    }

    return dateMoment.diff(minDateMoment, "days") >= nDaysApart;
  },
  message: (_, { minDate, nDaysApart }) => i18n.t("validations.afterDate", { minDate, nDaysApart }),
});

extend("before_date", {
  params: ["maxDate", "nDaysApart", "format"],
  validate: (date, { maxDate, nDaysApart, format }) => {
    // We only compare dates if they have valid formats.
    // Note that, normally, at this stage the 'date' is
    // valid as we check that first, but in case of we
    // do it again here.
    const dateMoment = moment(date, format, true);
    const maxDateMoment = moment(maxDate, format, true);
    if (!dateMoment.isValid() || !maxDateMoment.isValid()) {
      return true;
    }

    return maxDateMoment.diff(dateMoment, "days") >= nDaysApart;
  },
  message: (_, { maxDate, nDaysApart }) =>
    i18n.t("validations.beforeDate", { maxDate, nDaysApart }),
});

// To be used with vue-phone-number-input.
extend("phone_number", {
  params: ["payload"],
  validate(_, { payload }) {
    return payload && payload.phoneNumber && payload.isValid;
  },
  message: () => i18n.t("validations.phoneNumber"),
});

extend("excluded", {
  params: ["excludedValues", "message"],
  validate(value, { excludedValues }) {
    return (
      !value || excludedValues.filter((excludedValue) => isEqual(excludedValue, value)).length == 0
    );
  },
  message: (_, { message }) => message,
});

// We don't allow the name of the role to contain the ":" character because we use "role:" as
// prefix for the roles we save in Firebase's custom claims.
extend("role_format", {
  validate: (role) => {
    return !role.includes(":");
  },
  message: () => i18n.t("validations.roleFormat"),
});

extend("generic_component_content", {
  params: ["genericComponentSettings", "message"],
  validate: (_, { genericComponentSettings }) => {
    return (
      (genericComponentSettings.content != null && genericComponentSettings.content != "") ||
      genericComponentSettings.imageUrl != null ||
      genericComponentSettings.image != null ||
      (genericComponentSettings.rowData &&
        genericComponentSettings.rowData.length > 0 &&
        genericComponentSettings.columnDefs &&
        genericComponentSettings.columnDefs.length > 0) ||
      (genericComponentSettings.data &&
        genericComponentSettings.data.length > 0 &&
        genericComponentSettings.data.every((row) => (row.name || row.date) && row.value))
    );
  },
  message: (_, { message }) => message,
});

extend("sum", {
  params: ["expectedValue", "tolerance", "values"],
  validate(_, { expectedValue, tolerance, values }) {
    return (
      Math.abs(values.reduce((previous, current) => previous + current, 0) - expectedValue) <
      tolerance
    );
  },
  message: (_, { expectedValue, values }) => {
    return i18n.t("validations.sum", {
      expectedValue: expectedValue,
      currentValue: values.reduce((previous, current) => previous + current, 0)?.toFixed(2),
    });
  },
});

extend("max_file_size", {
  params: ["maxSizeBytes"],
  validate(file, { maxSizeBytes }) {
    return file && file.size != undefined && file.size <= maxSizeBytes;
  },
  message: (_, { maxSizeBytes }) =>
    i18n.t("validations.maxFileSize", {
      maxSizeBytes,
    }),
});
