import { i18n } from "@/main.js";

// All update frequencies we could have in the displayed products.
export const UpdateFrequency = Object.freeze({
  daily: {
    id: "daily",
    getLastChangeName: () => i18n.t("pages.products.overview.lastDayChange"),
  },
  weekly: {
    id: "weekly",
    getLastChangeName: () => i18n.t("pages.products.overview.lastWeekChange"),
  },
  monthly: {
    id: "monthly",
    getLastChangeName: () => i18n.t("pages.products.overview.lastMonthChange"),
  },
  quarterly: {
    id: "quarterly",
    getLastChangeName: () => i18n.t("pages.products.overview.lastQuarterChange"),
  },
  yearly: {
    id: "yearly",
    getLastChangeName: () => i18n.t("pages.products.overview.lastYearChange"),
  },
  other: {
    id: "other",
    getLastChangeName: () => i18n.t("pages.products.overview.lastChange"),
  },
});
