import { cloneDeep } from "lodash";

import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { STANDARD_PERF_STATS_WITH_BENCHMARK } from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];
const OLD_DATA_HIGHLIGHT_COLOR = "#ececec";

// ******---------- Lakefield Dynamic Global Core ----------*****
const dynamicGlobalCoreClassAEur = {
  productId: "dynamic-global-core-class-a-eur",
  storeModule: "dynamicGlobalCoreClassAEur",
  productName: "Lakefield Dynamic Global Core A EUR",
  productShortName: "Lakefield Dynamic Global Core",
  productColor: ORG_COLOR,
  productShareClassRoot: "dynamic-global-core",
  isDefaultShareClass: true,
  showLastPrice: true,
  oldDataEndDate: "2011-04-28",
  oldDataHighlightColor: OLD_DATA_HIGHLIGHT_COLOR,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const dynamicGlobalCoreClassBUsd = cloneDeep(dynamicGlobalCoreClassAEur);
dynamicGlobalCoreClassBUsd.productId = "dynamic-global-core-class-b-usd";
dynamicGlobalCoreClassBUsd.storeModule = "dynamicGlobalCoreClassBUsd";
dynamicGlobalCoreClassBUsd.productName = "Lakefield Dynamic Global Core B USD";
dynamicGlobalCoreClassBUsd.isDefaultShareClass = false;

const dynamicGlobalCoreClassCChf = cloneDeep(dynamicGlobalCoreClassAEur);
dynamicGlobalCoreClassCChf.productId = "dynamic-global-core-class-c-chf";
dynamicGlobalCoreClassCChf.storeModule = "dynamicGlobalCoreClassCChf";
dynamicGlobalCoreClassCChf.productName = "Lakefield Dynamic Global Core C CHF";
dynamicGlobalCoreClassCChf.isDefaultShareClass = false;

const dynamicGlobalCoreClassDistEur = cloneDeep(dynamicGlobalCoreClassAEur);
dynamicGlobalCoreClassDistEur.productId = "dynamic-global-core-class-dist-eur";
dynamicGlobalCoreClassDistEur.storeModule = "dynamicGlobalCoreClassDistEur";
dynamicGlobalCoreClassDistEur.productName = "Lakefield Dynamic Global Core Dist EUR";
dynamicGlobalCoreClassDistEur.isDefaultShareClass = false;
dynamicGlobalCoreClassDistEur.oldDataEndDate = "2019-04-11";

const dynamicGlobalCoreClassDistUsd = cloneDeep(dynamicGlobalCoreClassAEur);
dynamicGlobalCoreClassDistUsd.productId = "dynamic-global-core-class-dist-usd";
dynamicGlobalCoreClassDistUsd.storeModule = "dynamicGlobalCoreClassDistUsd";
dynamicGlobalCoreClassDistUsd.productName = "Lakefield Dynamic Global Core Dist USD";
dynamicGlobalCoreClassDistUsd.isDefaultShareClass = false;
dynamicGlobalCoreClassDistUsd.oldDataEndDate = "2017-09-21";

// ******---------- LAKEFIELD (CH) SWISS LARGE CAP EQUITY ----------*****

const chSwissLargeCapEquityAChf = {
  productId: "ch-swiss-large-cap-equity-a-chf",
  storeModule: "chSwissLargeCapEquityAChf",
  productName: "Lakefield (CH) Swiss Large Cap Equity A CHF",
  productShortName: "Lakefield (CH) Swiss Large Cap Equity",
  productColor: ORG_COLOR,
  productShareClassRoot: "ch-swiss-large-cap-equity",
  isDefaultShareClass: true,
  showLastPrice: true,
  oldDataEndDate: "2018-10-24",
  oldDataHighlightColor: OLD_DATA_HIGHLIGHT_COLOR,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const chSwissLargeCapEquityZChf = cloneDeep(chSwissLargeCapEquityAChf);
chSwissLargeCapEquityZChf.productId = "ch-swiss-large-cap-equity-z-chf";
chSwissLargeCapEquityZChf.storeModule = "chSwissLargeCapEquityZChf";
chSwissLargeCapEquityZChf.productName = "Lakefield (CH) Swiss Large Cap Equity Z CHF";
chSwissLargeCapEquityZChf.isDefaultShareClass = false;
chSwissLargeCapEquityZChf.oldDataEndDate = "2019-01-30";

// ******---------- LAKEFIELD (CH) SWISS MID & SMALL CAP EQUITY  ----------*****

const chSwissMidSmallCapEquityAChf = {
  productId: "ch-swiss-mid-small-cap-equity-a-chf",
  storeModule: "chSwissMidSmallCapEquityAChf",
  productName: "Lakefield (CH) Swiss Mid & Small Cap Equity A CHF",
  productShortName: "Lakefield (CH) Swiss Mid & Small Cap Equity",
  productColor: ORG_COLOR,
  productShareClassRoot: "ch-swiss-mid-small-cap-equity",
  isDefaultShareClass: true,
  showLastPrice: true,
  oldDataEndDate: "2018-10-24",
  oldDataHighlightColor: OLD_DATA_HIGHLIGHT_COLOR,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const chSwissMidSmallCapEquityZChf = cloneDeep(chSwissMidSmallCapEquityAChf);
chSwissMidSmallCapEquityZChf.productId = "ch-swiss-mid-small-cap-equity-z-chf";
chSwissMidSmallCapEquityZChf.storeModule = "chSwissMidSmallCapEquityZChf";
chSwissMidSmallCapEquityZChf.productName = "Lakefield (CH) Swiss Mid & Small Cap Equity Z CHF";
chSwissMidSmallCapEquityZChf.isDefaultShareClass = false;
chSwissMidSmallCapEquityZChf.oldDataEndDate = "2022-08-03";

// ******---------- LAKEFIELD (CH) SWISS EQUITY RISK-CONTROLLED  ----------*****

const chSwissEquityRiskControlledAChf = {
  productId: "ch-swiss-equity-risk-controlled-a-chf",
  storeModule: "chSwissEquityRiskControlledAChf",
  productName: "Lakefield (CH) Swiss Equity Risk-Controlled A CHF",
  productShortName: "Lakefield (CH) Swiss Equity Risk-Controlled",
  productColor: ORG_COLOR,
  showLastPrice: true,
  oldDataEndDate: "2018-10-24",
  oldDataHighlightColor: OLD_DATA_HIGHLIGHT_COLOR,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

// ******---------- LAKEFIELD (CH) SWISS EQUITY LONG SHORT  ----------*****

const chSwissEquityLongShortAChf = {
  productId: "ch-swiss-equity-long-short-a-chf",
  storeModule: "chSwissEquityLongShortAChf",
  productName: "Lakefield (CH) Swiss Equity Long/Short A CHF",
  productShortName: "Lakefield (CH) Swiss Equity Long/Short",
  productColor: ORG_COLOR,
  showLastPrice: true,
  oldDataEndDate: "2018-10-24",
  oldDataHighlightColor: OLD_DATA_HIGHLIGHT_COLOR,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

// ******---------- LAKEFIELD (CH) STABLE MARKET NEUTRAL  ----------*****

const chStableMarketNeutralAChf = {
  productId: "ch-stable-market-neutral-a-chf",
  storeModule: "chStableMarketNeutralAChf",
  productName: "Lakefield (CH) Stable Market Neutral A CHF",
  productShortName: "Lakefield (CH) Stable Market Neutral",
  productColor: ORG_COLOR,
  productShareClassRoot: "ch-stable-market-neutral",
  isDefaultShareClass: true,
  showLastPrice: true,
  oldDataEndDate: "2021-10-14",
  oldDataHighlightColor: OLD_DATA_HIGHLIGHT_COLOR,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const chStableMarketNeutralCEur = cloneDeep(chStableMarketNeutralAChf);
chStableMarketNeutralCEur.productId = "ch-stable-market-neutral-c-eur";
chStableMarketNeutralCEur.storeModule = "chStableMarketNeutralCEur";
chStableMarketNeutralCEur.productName = "Lakefield (CH) Stable Market Neutral C EUR";
chStableMarketNeutralCEur.isDefaultShareClass = false;

const chStableMarketNeutralBUsd = cloneDeep(chStableMarketNeutralAChf);
chStableMarketNeutralBUsd.productId = "ch-stable-market-neutral-b-usd";
chStableMarketNeutralBUsd.storeModule = "chStableMarketNeutralBUsd";
chStableMarketNeutralBUsd.productName = "Lakefield (CH) Stable Market Neutral B USD";
chStableMarketNeutralBUsd.isDefaultShareClass = false;

// ******---------- LAKEFIELD - DYNAMIC GLOBAL BOND  ----------*****

const dynamicGlobalBondClassAEur = {
  productId: "dynamic-global-bond-class-a-eur",
  storeModule: "dynamicGlobalBondClassAEur",
  productName: "Lakefield Dynamic Global Bond A EUR",
  productShortName: "Lakefield Dynamic Global Bond",
  productColor: ORG_COLOR,
  productShareClassRoot: "dynamic-global-bond",
  isDefaultShareClass: true,
  showLastPrice: true,
  oldDataEndDate: "2012-11-01",
  oldDataHighlightColor: OLD_DATA_HIGHLIGHT_COLOR,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const dynamicGlobalBondClassBUsd = cloneDeep(dynamicGlobalBondClassAEur);
dynamicGlobalBondClassBUsd.productId = "dynamic-global-bond-class-b-usd";
dynamicGlobalBondClassBUsd.storeModule = "dynamicGlobalBondClassBUsd";
dynamicGlobalBondClassBUsd.productName = "Lakefield Dynamic Global Bond B USD";
dynamicGlobalCoreClassBUsd.isDefaultShareClass = false;

const dynamicGlobalBondClassCChf = cloneDeep(dynamicGlobalBondClassAEur);
dynamicGlobalBondClassCChf.productId = "dynamic-global-bond-class-c-chf";
dynamicGlobalBondClassCChf.storeModule = "dynamicGlobalBondClassCChf";
dynamicGlobalBondClassCChf.productName = "Lakefield Dynamic Global Bond C CHF";
dynamicGlobalBondClassCChf.isDefaultShareClass = false;

const dynamicGlobalBondClassDistUsd = cloneDeep(dynamicGlobalBondClassAEur);
dynamicGlobalBondClassDistUsd.productId = "dynamic-global-bond-class-dist-usd";
dynamicGlobalBondClassDistUsd.storeModule = "dynamicGlobalBondClassDistUsd";
dynamicGlobalBondClassDistUsd.productName = "Lakefield Dynamic Global Bond DIST USD";
dynamicGlobalBondClassDistUsd.isDefaultShareClass = false;
dynamicGlobalBondClassDistUsd.oldDataEndDate = "2017-10-12";

// ******---------- LAKEFIELD - WORLD EQUITY  ----------*****

const dynamicWorldEquityClassAEur = {
  productId: "dynamic-world-equity-class-a-eur",
  storeModule: "dynamicWorldEquityClassAEur",
  productName: "Lakefield Dynamic World Equity A EUR",
  productShortName: "Lakefield Dynamic World Equity",
  productColor: ORG_COLOR,
  productShareClassRoot: "dynamic-world-equity",
  isDefaultShareClass: true,
  showLastPrice: true,
  oldDataEndDate: "2015-08-12",
  oldDataHighlightColor: OLD_DATA_HIGHLIGHT_COLOR,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const dynamicWorldEquityClassBUsd = cloneDeep(dynamicWorldEquityClassAEur);
dynamicWorldEquityClassBUsd.productId = "dynamic-world-equity-class-b-usd";
dynamicWorldEquityClassBUsd.storeModule = "dynamicWorldEquityClassBUsd";
dynamicWorldEquityClassBUsd.productName = "Lakefield Dynamic World Equity B USD";
dynamicGlobalCoreClassBUsd.isDefaultShareClass = false;

const dynamicWorldEquityClassCChf = cloneDeep(dynamicWorldEquityClassAEur);
dynamicWorldEquityClassCChf.productId = "dynamic-world-equity-class-c-chf";
dynamicWorldEquityClassCChf.storeModule = "dynamicWorldEquityClassCChf";
dynamicWorldEquityClassCChf.productName = "Lakefield Dynamic World Equity C CHF";
dynamicWorldEquityClassCChf.isDefaultShareClass = false;

const dynamicWorldEquityClassDistUsd = cloneDeep(dynamicWorldEquityClassAEur);
dynamicWorldEquityClassDistUsd.productId = "dynamic-world-equity-class-dist-usd";
dynamicWorldEquityClassDistUsd.storeModule = "dynamicWorldEquityClassDistUsd";
dynamicWorldEquityClassDistUsd.productName = "Lakefield Dynamic World Equity DIST USD";
dynamicWorldEquityClassDistUsd.isDefaultShareClass = false;
dynamicWorldEquityClassDistUsd.oldDataEndDate = "2017-10-12";

// ******---------- LAKEFIELD - SWISS MID & SMALL CAP EQUITY ----------*****

const swissMidSmallCapEquityClassAChf = {
  productId: "swiss-mid-small-cap-equity-class-a-chf",
  storeModule: "swissMidSmallCapEquityClassAChf",
  productName: "Lakefield Swiss Mid & Small Cap Equity A CHF",
  productShortName: "Lakefield Swiss Mid & Small Cap Equity",
  productColor: ORG_COLOR,
  productShareClassRoot: "swiss-mid-small-cap-equity",
  isDefaultShareClass: true,
  showLastPrice: true,
  oldDataEndDate: "2016-08-03",
  oldDataHighlightColor: OLD_DATA_HIGHLIGHT_COLOR,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const swissMidSmallCapEquityClassBUsd = cloneDeep(swissMidSmallCapEquityClassAChf);
swissMidSmallCapEquityClassBUsd.productId = "swiss-mid-small-cap-equity-class-b-usd";
swissMidSmallCapEquityClassBUsd.storeModule = "swissMidSmallCapEquityClassBUsd";
swissMidSmallCapEquityClassBUsd.productName = "Lakefield Swiss Mid & Small Cap Equity B USD";
swissMidSmallCapEquityClassBUsd.isDefaultShareClass = false;
swissMidSmallCapEquityClassBUsd.oldDataEndDate = "2017-09-28";

const swissMidSmallCapEquityClassCEur = cloneDeep(swissMidSmallCapEquityClassAChf);
swissMidSmallCapEquityClassCEur.productId = "swiss-mid-small-cap-equity-class-c-eur";
swissMidSmallCapEquityClassCEur.storeModule = "swissMidSmallCapEquityClassCEur";
swissMidSmallCapEquityClassCEur.productName = "Lakefield Swiss Mid & Small Cap Equity C EUR";
swissMidSmallCapEquityClassCEur.isDefaultShareClass = false;
swissMidSmallCapEquityClassCEur.oldDataEndDate = "2016-12-01";

const swissMidSmallCapEquityClassDistUsd = cloneDeep(swissMidSmallCapEquityClassAChf);
swissMidSmallCapEquityClassDistUsd.productId = "swiss-mid-small-cap-equity-class-dist-usd";
swissMidSmallCapEquityClassDistUsd.storeModule = "swissMidSmallCapEquityClassDistUsd";
swissMidSmallCapEquityClassDistUsd.productName = "Lakefield Swiss Mid & Small Cap Equity DIST USD";
swissMidSmallCapEquityClassDistUsd.isDefaultShareClass = false;
swissMidSmallCapEquityClassDistUsd.oldDataEndDate = "2017-10-12";

// ******---------- LAKEFIELD - SWISS LARGE CAP EQUITY ----------*****

const swissLargeCapEquityClassAChf = {
  productId: "swiss-large-cap-equity-class-a-chf",
  storeModule: "swissLargeCapEquityClassAChf",
  productName: "Lakefield Swiss Large Cap Equity A CHF",
  productShortName: "Lakefield Swiss Large Cap Equity",
  productColor: ORG_COLOR,
  productShareClassRoot: "swiss-large-cap-equity",
  isDefaultShareClass: true,
  showLastPrice: true,
  oldDataEndDate: "2016-11-30",
  oldDataHighlightColor: OLD_DATA_HIGHLIGHT_COLOR,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const swissLargeCapEquityClassBUsd = cloneDeep(swissLargeCapEquityClassAChf);
swissLargeCapEquityClassBUsd.productId = "swiss-large-cap-equity-class-b-usd";
swissLargeCapEquityClassBUsd.storeModule = "swissLargeCapEquityClassBUsd";
swissLargeCapEquityClassBUsd.productName = "Lakefield Swiss Large Cap Equity B USD";
swissLargeCapEquityClassBUsd.isDefaultShareClass = false;

const swissLargeCapEquityClassCEur = cloneDeep(swissLargeCapEquityClassAChf);
swissLargeCapEquityClassCEur.productId = "swiss-large-cap-equity-class-c-eur";
swissLargeCapEquityClassCEur.storeModule = "swissLargeCapEquityClassCEur";
swissLargeCapEquityClassCEur.productName = "Lakefield Swiss Large Cap Equity C EUR";
swissLargeCapEquityClassCEur.isDefaultShareClass = false;

const swissLargeCapEquityClassDistUsd = cloneDeep(swissLargeCapEquityClassAChf);
swissLargeCapEquityClassDistUsd.productId = "swiss-large-cap-equity-class-dist-usd";
swissLargeCapEquityClassDistUsd.storeModule = "swissLargeCapEquityClassDistUsd";
swissLargeCapEquityClassDistUsd.productName = "Lakefield Swiss Large Cap Equity DIST USD";
swissLargeCapEquityClassDistUsd.isDefaultShareClass = false;
swissLargeCapEquityClassDistUsd.oldDataEndDate = "2017-10-12";

// ******---------- LAKEFIELD - SWISS EQUITY LONG SHORT ----------*****

const swissEquityLongShortClassAChf = {
  productId: "swiss-equity-long-short-class-a-chf",
  storeModule: "swissEquityLongShortClassAChf",
  productName: "Lakefield Swiss Equity Long/Short A CHF",
  productShortName: "Lakefield Swiss Equity Long/Short",
  productColor: ORG_COLOR,
  productShareClassRoot: "swiss-equity-long-short",
  isDefaultShareClass: true,
  showLastPrice: true,
  oldDataEndDate: "2017-02-01",
  oldDataHighlightColor: OLD_DATA_HIGHLIGHT_COLOR,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const swissEquityLongShortClassBUsd = cloneDeep(swissEquityLongShortClassAChf);
swissEquityLongShortClassBUsd.productId = "swiss-equity-long-short-class-b-usd";
swissEquityLongShortClassBUsd.storeModule = "swissEquityLongShortClassBUsd";
swissEquityLongShortClassBUsd.productName = "Lakefield Swiss Equity Long/Short B USD";
swissEquityLongShortClassBUsd.isDefaultShareClass = false;

const swissEquityLongShortClassCEur = cloneDeep(swissEquityLongShortClassAChf);
swissEquityLongShortClassCEur.productId = "swiss-equity-long-short-class-c-eur";
swissEquityLongShortClassCEur.storeModule = "swissEquityLongShortClassCEur";
swissEquityLongShortClassCEur.productName = "Lakefield Swiss Equity Long/Short C EUR";
swissEquityLongShortClassCEur.isDefaultShareClass = false;

const swissEquityLongShortClassDistUsd = cloneDeep(swissEquityLongShortClassAChf);
swissEquityLongShortClassDistUsd.productId = "swiss-equity-long-short-class-dist-usd";
swissEquityLongShortClassDistUsd.storeModule = "swissEquityLongShortClassDistUsd";
swissEquityLongShortClassDistUsd.productName = "Lakefield Swiss Equity Long/Short DIST USD";
swissEquityLongShortClassDistUsd.isDefaultShareClass = false;
swissEquityLongShortClassDistUsd.oldDataEndDate = "2017-10-12";

// ******---------- LAKEFIELD DYNAMIC SWISS CORE ----------*****

const dynamicSwissCoreChf = {
  productId: "dynamic-swiss-core-chf",
  storeModule: "dynamicSwissCoreChf",
  productName: "Lakefield Dynamic Swiss Core CHF",
  productShortName: "Lakefield Dynamic Swiss Core",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.monthly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

// ******---------- LAKEFIELD SWISS DIVIDEND INCOME ----------*****

const swissDividendIncomeChf = {
  productId: "swiss-dividend-income-chf",
  storeModule: "swissDividendIncomeChf",
  productName: "Lakefield Swiss Dividend Income CHF",
  productShortName: "Lakefield Swiss Dividend Income",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.monthly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

export const LAKEFIELD_PRODUCTS_DETAILS = [
  chSwissLargeCapEquityAChf,
  chSwissLargeCapEquityZChf,
  chSwissMidSmallCapEquityAChf,
  chSwissMidSmallCapEquityZChf,
  chSwissEquityRiskControlledAChf,
  chSwissEquityLongShortAChf,
  chStableMarketNeutralAChf,
  chStableMarketNeutralBUsd,
  chStableMarketNeutralCEur,
  dynamicGlobalBondClassAEur,
  dynamicGlobalBondClassBUsd,
  dynamicGlobalBondClassCChf,
  dynamicGlobalBondClassDistUsd,
  dynamicGlobalCoreClassAEur,
  dynamicGlobalCoreClassBUsd,
  dynamicGlobalCoreClassCChf,
  dynamicGlobalCoreClassDistEur,
  dynamicGlobalCoreClassDistUsd,
  dynamicWorldEquityClassAEur,
  dynamicWorldEquityClassBUsd,
  dynamicWorldEquityClassCChf,
  dynamicWorldEquityClassDistUsd,
  swissMidSmallCapEquityClassAChf,
  swissMidSmallCapEquityClassBUsd,
  swissMidSmallCapEquityClassCEur,
  swissMidSmallCapEquityClassDistUsd,
  swissLargeCapEquityClassAChf,
  swissLargeCapEquityClassBUsd,
  swissLargeCapEquityClassCEur,
  swissLargeCapEquityClassDistUsd,
  swissEquityLongShortClassAChf,
  swissEquityLongShortClassBUsd,
  swissEquityLongShortClassCEur,
  swissEquityLongShortClassDistUsd,
  dynamicSwissCoreChf,
  swissDividendIncomeChf,
];

export const LAKEFIELD_PRODUCTS = LAKEFIELD_PRODUCTS_DETAILS.map((details) => details.storeModule);
