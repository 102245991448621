<template lang="html">
  <div :class="[`vs-tabs-${color}`, `vs-tabs-position-${position}`]" class="con-vs-tabs vs-tabs">
    <div class="con-ul-tabs">
      <ul ref="ul" :class="[`ul-tabs-${alignment}`]" class="ul-tabs vs-tabs--ul">
        <li
          v-for="(child, index) in children"
          :key="index"
          ref="li"
          :class="{ activeChild: childActive == index }"
          :style="childActive == index ? styleTab : {}"
          class="vs-tabs--li"
          @mouseover="hover = true"
          @mouseout="hover = false"
        >
          <button
            v-bind="child.attrs"
            :style="styleAlignIcon(child.icon)"
            class="vs-tabs--btn"
            type="button"
            @click="activeChild(index)"
            v-on="child.listeners"
          >
            <vs-icon
              v-if="child.icon"
              v-tooltip="
                child.iconTooltipContent
                  ? {
                      content: child.iconTooltipContent,
                      boundariesElement: 'window',
                    }
                  : null
              "
              :icon-pack="child.iconPack"
              :icon="child.icon"
              :color="color"
              :size="child.iconSize"
              class="vs-tabs--btn-icon"
              @mouseenter="logTooltip(child.icon)"
            ></vs-icon>
            <span v-if="child.label">{{ child.label }}</span>
          </button>

          <button v-if="child.tag" class="vs-tabs--btn-tag" @click="clickTag(child)">
            <vs-icon
              :icon-pack="child.iconPack"
              :icon="child.tag"
              :color="child.tagColor"
            ></vs-icon>
          </button>
        </li>
      </ul>
      <span :style="stylex" class="line-vs-tabs" />
    </div>
    <div class="con-slot-tabs">
      <slot />
    </div>
  </div>
</template>

<script>
import { TOOLTIP_DISPLAYED_EVENT } from "@/assets/constants/analytics";
import exports from "@/assets/scss/exports.module.scss";

export default {
  name: "VxTabs",
  props: {
    value: {
      default: 0,
      type: [Number, String],
    },
    color: {
      default: "primary",
      type: String,
    },
    tagColor: {
      default: "primary",
      type: String,
    },
    alignment: {
      default: "left",
      type: String,
    },
    position: {
      default: "top",
      type: String,
    },
    validateTabSwitchFunc: {
      type: Function,
    },
  },
  data: () => ({
    topx: "auto",
    heightx: 2,
    hover: false,
    children: [],
    childActive: 0,
    leftx: 0,
    widthx: 0,
    these: false,
  }),
  computed: {
    styleTab() {
      return {
        color: exports[`color-${this.color}`],
      };
    },
    stylex() {
      return {
        top: `${this.topx}px`,
        left: `${this.leftx}px`,
        width: `${this.widthx}px`,
        height: `${this.heightx}px`,
        background: `linear-gradient(30deg, ${
          exports[`color-${this.color}`]
        } 0%, ${exports[`color-${this.color}`]} 100%)`,
        boxShadow: `0px 0px 8px 0px ${exports[`color-${this.color}`]}`,
        transform: `scaleX(${this.these ? 1.3 : 1})`,
      };
    },
  },
  watch: {
    // When changing the language, the tab titles will vary in size,
    // hence we need to realign the bar at the bottom of the active
    // tab. However, this watcher trigger too soon, as the title
    // won't have been translated as fast, hence we wait a tiny
    // bit before trigger translation of the bar.
    "$i18n.locale"() {
      setTimeout(() => {
        this.changePositionLine(this.$refs.li[this.childActive]);
      }, 100);
    },
    value(newValue) {
      this.activeChild(newValue);
    },
  },
  mounted() {
    const activeIndex = this.value;
    this.childActive = activeIndex;
    this.$nextTick(() => {
      this.activeChild(activeIndex, true);
    });
  },
  methods: {
    clickTag(child) {
      this.$emit("click-tag", child);
    },
    styleAlignIcon(icon) {
      return icon ? "display:flex;align-items:center" : "";
    },
    parseIndex(index) {
      let activeIndex = this.childActive;
      if (index < 0) {
        activeIndex = 0;
      } else if (index >= this.$children.length) {
        activeIndex = this.$children.length - 1;
      } else if (typeof this.$children[index].$attrs.disabled === "undefined") {
        activeIndex = parseInt(index);
      }
      return activeIndex;
    },
    activeChild(index, initialAnimation) {
      // If provided, check that we are allowed to switch tab.
      if (this.validateTabSwitchFunc && !this.validateTabSwitchFunc()) {
        return;
      }

      initialAnimation = !!initialAnimation;
      const elem = this.$refs.li[index];
      if (this.childActive == index && !initialAnimation) {
        this.these = true;
        elem.classList.add("isActive");
        setTimeout(() => {
          elem.classList.remove("isActive");
          this.these = false;
        }, 200);
      }

      this.$children.map((item, item_index) => {
        if (item_index != index) {
          item.active = false;
        }
      });

      if (this.childActive > index) {
        this.$children[index].invert = true;
        this.$children[this.childActive].invert = false;
      } else {
        this.$children[this.childActive].invert = true;
        this.$children[index].invert = false;
      }

      this.$children[index].active = true;
      this.childActive = index;
      this.$emit("input", this.childActive);

      if (this.position == "left" || this.position == "right") {
        this.$children[index].vertical = true;
      }

      this.changePositionLine(elem, initialAnimation);
    },
    changePositionLine(elem, initialAnimation) {
      if (this.position == "left" || this.position == "right") {
        this.topx = elem.offsetTop;
        this.heightx = elem.offsetHeight;
        this.widthx = 2;
      } else {
        const update = () => {
          this.leftx = elem.offsetLeft;
          this.widthx = elem.offsetWidth;
          this.topx =
            elem.offsetHeight +
            (elem.getBoundingClientRect().top - this.$refs.ul.getBoundingClientRect().top);
        };
        if (!initialAnimation) {
          update();
        } else {
          setTimeout(update, 100);
        }
      }
    },
    logTooltip(tooltip) {
      this.$analytics.logEvent(TOOLTIP_DISPLAYED_EVENT, {
        tooltip,
      });
    },
  },
};
</script>
