import "firebase/compat/firestore";

import { multiFactor } from "firebase/auth";
import firebase from "firebase/compat/app";
import { isEqual } from "lodash";
import { firestoreAction } from "vuexfire";

import { Role } from "@/acl/roles.js";
import { IP_COUNTRY_KEY, IP_TYPE_KEY } from "@/assets/constants/investorProfile";
import { getNested } from "@/store/utils.js";

export default {
  namespaced: true,
  state: {
    userInfo: null,
    ipTypeBrowserStored: undefined,
    ipCountryBrowserStored: undefined,
    currentUserRoles: new Set([Role.guest]),
    lastTokenIssuedDay: null,
  },
  mutations: {
    FETCH_USER_INFO_FAILURE(state) {
      state.userInfo = undefined;
    },
    UPDATE_IP_TYPE_BROWSER_STORED(state, { ipType }) {
      state.ipTypeBrowserStored = ipType;
    },
    UPDATE_IP_COUNTRY_BROWSER_STORED(state, { ipCountry }) {
      state.ipCountryBrowserStored = ipCountry;
    },
    UPDATE_CURRENT_USER_ROLES(state, { currentUserRoles }) {
      // As the currentUserRoles is a Set, if we update it, all the computed using "hasAnyRole"
      // will retrigger, and it's costly when changing a route, especially when the number of
      // products shown on the platform increases. Therefore, if not needed, we do not update
      // it.
      if (!isEqual(currentUserRoles, state.currentUserRoles)) {
        state.currentUserRoles = currentUserRoles;
      }
    },
    UPDATE_LAST_TOKEN_ISSUED_DAY(state, { lastTokenIssuedDay }) {
      state.lastTokenIssuedDay = lastTokenIssuedDay;
    },
  },
  actions: {
    bindUserInfoRef: firestoreAction(({ bindFirestoreRef, commit }) => {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        return Promise.resolve();
      }

      return bindFirestoreRef(
        "userInfo",
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid)
      ).catch((error) => {
        // Note that we only do this if the user is still set,
        // as the most likely case is that we tried to log in the
        // user, it succeeded but we needed to sign out him, and
        // thus the binding promise above fail with a "Missing permission".
        // as it already started to get the link with Firestore
        // while we signed out the user.
        if (firebase.auth().currentUser) {
          commit("FETCH_USER_INFO_FAILURE");
          throw error;
        }
      });
    }),
    updateUserInfo(_, { newUserInfo }) {
      const userRef = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid);
      return userRef.set(newUserInfo, { merge: true });
    },
    addFollowingProduct(_, { productId, lang }) {
      const userRef = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid);
      return userRef.set(
        {
          followingProducts: firebase.firestore.FieldValue.arrayUnion({
            productId,
            lang,
          }),
        },
        { merge: true }
      );
    },
    removeFollowingProduct(_, { productId }) {
      const userRef = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid);
      // First, we need to find the exact object stored in Firestore because we
      // don't know the language in which the user followed the product.
      return userRef.get().then((userDoc) => {
        const correspondingObj = userDoc
          .data()
          .followingProducts.find((obj) => obj.productId === productId);
        // Now that we have the object, we can remove it from Firestore.
        // Note that we assume that a user can only follow a product in a single language.
        return userRef.set(
          {
            followingProducts: firebase.firestore.FieldValue.arrayRemove(correspondingObj),
          },
          { merge: true }
        );
      });
    },
    // Create new user document in firestore.
    createUserInfo(_, { uid, userInfo }) {
      return firebase.firestore().collection("users").doc(uid).set(userInfo, { merge: true });
    },
    setIpTypeFromBrowserLocalStorage({ commit }) {
      return new Promise((resolve) => {
        commit("UPDATE_IP_TYPE_BROWSER_STORED", {
          ipType: localStorage.getItem(IP_TYPE_KEY),
        });
        resolve();
      });
    },
    updateIpTypeBrowserLocalStorage({ commit }, { newIpType }) {
      return new Promise((resolve) => {
        localStorage.setItem(IP_TYPE_KEY, newIpType);
        commit("UPDATE_IP_TYPE_BROWSER_STORED", { ipType: newIpType });
        resolve();
      });
    },
    setIpCountryFromBrowserLocalStorage({ commit }) {
      return new Promise((resolve) => {
        commit("UPDATE_IP_COUNTRY_BROWSER_STORED", {
          ipCountry: localStorage.getItem(IP_COUNTRY_KEY),
        });
        resolve();
      });
    },
    updateIpCountryBrowserLocalStorage({ commit }, { newIpCountry }) {
      return new Promise((resolve) => {
        localStorage.setItem(IP_COUNTRY_KEY, newIpCountry);
        commit("UPDATE_IP_COUNTRY_BROWSER_STORED", { ipCountry: newIpCountry });
        resolve();
      });
    },
    async setAndGetUserRoles({ commit, state, dispatch }) {
      const user = firebase.auth().currentUser;
      if (!user) {
        commit("UPDATE_CURRENT_USER_ROLES", {
          currentUserRoles: new Set([Role.guest]),
        });
      } else {
        const token = await user.getIdTokenResult();
        const issuedOnDay = new Date(token.issuedAtTime).toISOString().split("T")[0];
        if (state.lastTokenIssuedDay == null || issuedOnDay != state.lastTokenIssuedDay) {
          await dispatch("updateUserInfo", {
            newUserInfo: {
              lastVisitedDay: issuedOnDay,
            },
          });
          commit("UPDATE_LAST_TOKEN_ISSUED_DAY", {
            lastTokenIssuedDay: issuedOnDay,
          });
        }

        // Get all roles and remove the "role:" substring.
        const firebaseRoles = Object.keys(token.claims)
          .filter((key) => key.startsWith("role:") && token.claims[key])
          .map((role) => role.substring("role:".length));

        // Add user role to be able to detect between authenticated user and non-authenticated user.
        firebaseRoles.push(Role.insight);
        commit("UPDATE_CURRENT_USER_ROLES", {
          currentUserRoles: new Set(firebaseRoles),
        });
      }
    },
  },
  getters: {
    getUserInfo: (state) => {
      return state.userInfo;
    },
    getFirstName: (state) => {
      return getNested(state.userInfo, "firstName");
    },
    getLastName: (state) => {
      return getNested(state.userInfo, "lastName");
    },
    getEmail: (state) => {
      return getNested(state.userInfo, "email");
    },
    getCountry: (state) => {
      return getNested(state.userInfo, "country");
    },
    getInvestorProfile: (state) => {
      return getNested(state.userInfo, "investorProfile");
    },
    getCompany: (state) => {
      return getNested(state.userInfo, "company");
    },
    getTelNumber: (state) => {
      return getNested(state.userInfo, "telNumber");
    },
    getTelNumberCountryCode: (state) => {
      return getNested(state.userInfo, "telNumberCountryCode");
    },
    hasRegistered2fa: () => {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        return false;
      }

      const multiFactorUser = multiFactor(currentUser);
      return multiFactorUser.enrolledFactors.some((factor) => factor.factorId === "totp");
    },
    isFollowingProduct:
      (state) =>
      ({ productId, lang }) => {
        const followingProducts = getNested(state.userInfo, "followingProducts");
        if (!followingProducts) {
          return false;
        }

        // If no 'lang' given, just check if the user follows the product in any language.
        if (!lang) {
          return followingProducts.map((obj) => obj.productId).includes(productId);
        }

        return (
          followingProducts.filter((obj) => {
            obj.productId === productId && obj.lang === lang;
          }).length > 0
        );
      },
    getIpTypeBrowserStored: (state) => {
      return state.ipTypeBrowserStored;
    },
    getIpCountryBrowserStored: (state) => {
      return state.ipCountryBrowserStored;
    },
    getRoles: (state) => {
      return state.currentUserRoles;
    },
    hasAnyRole: (state) => (role) => {
      return state.currentUserRoles.has(role);
    },
    hasAnyRoles: (_, getters) => (roles) => {
      return roles.some((role) => getters.hasAnyRole(role));
    },
    isAdminOf: (_1, getters, _2, rootGetters) => (productId) => {
      // Return true if the user is a superAdmin or an admin.
      if (getters.hasAnyRoles([Role.superAdmin, Role.admin])) {
        return true;
      }

      // Otherwise, only return true if the user is assigned at least one user-defined role which
      // gives admin access to the given productId.
      const iam = rootGetters["platformSettings/getIam"];
      const userDefinedRoles = iam?.userDefinedRoles;
      if (userDefinedRoles) {
        return userDefinedRoles.some(
          (role) => role.allowedProductIds.includes(productId) && getters.hasAnyRole(role.name)
        );
      }

      return false;
    },
  },
};
