import { cloneDeep } from "lodash";

import { Role } from "@/acl/roles";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { STANDARD_PERF_STATS_WITHOUT_BENCHMARK } from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];

// Roles allowed to see the private mandates.
const PRIVATE_MANDATES_ALLOWED_ROLES = [Role.admin, Role.superAdmin, Role.viewer];

// ******---------- B&I Global ----------*****
const biGlobalSUsdAcc = {
  productId: "bi-global-s-usd-acc",
  storeModule: "biGlobalSUsdAcc",
  productName: "B&I Global S (USD Accumulating)",
  productShortName: "B&I Global",
  productColor: ORG_COLOR,
  productShareClassRoot: "bi-global",
  isDefaultShareClass: true,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  langsForDocuments: ["en", "de"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const biGlobalBUsdDis = cloneDeep(biGlobalSUsdAcc);
biGlobalBUsdDis.productId = "bi-global-b-usd-dis";
biGlobalBUsdDis.storeModule = "biGlobalBUsdDis";
biGlobalBUsdDis.productName = "B&I Global B (USD Distributing)";
biGlobalBUsdDis.isDefaultShareClass = false;

const biGlobalCChfHAcc = cloneDeep(biGlobalSUsdAcc);
biGlobalCChfHAcc.productId = "bi-global-c-chf-h-acc";
biGlobalCChfHAcc.storeModule = "biGlobalCChfHAcc";
biGlobalCChfHAcc.productName = "B&I Global C (CHF-hedged Accumulating)";
biGlobalCChfHAcc.isDefaultShareClass = false;

const biGlobalEEurHAcc = cloneDeep(biGlobalSUsdAcc);
biGlobalEEurHAcc.productId = "bi-global-e-eur-h-acc";
biGlobalEEurHAcc.storeModule = "biGlobalEEurHAcc";
biGlobalEEurHAcc.productName = "B&I Global E (EUR-hedged Accumulating)";
biGlobalEEurHAcc.isDefaultShareClass = false;

const biGlobalGGBPHDis = cloneDeep(biGlobalSUsdAcc);
biGlobalGGBPHDis.productId = "bi-global-g-gbp-h-dis";
biGlobalGGBPHDis.storeModule = "biGlobalGGBPHDis";
biGlobalGGBPHDis.productName = "B&I Global G (GBP-hedged Distributing)";
biGlobalGGBPHDis.isDefaultShareClass = false;

// ******---------- B&I Asian ----------*****
const biAsianAUsdAcc = {
  productId: "bi-asian-a-usd-acc",
  storeModule: "biAsianAUsdAcc",
  productName: "B&I Asian A (USD Accumulating)",
  productShortName: "B&I Asian",
  productColor: ORG_COLOR,
  productShareClassRoot: "bi-asian",
  isDefaultShareClass: true,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  langsForDocuments: ["en", "de"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const biAsianBUsdDis = cloneDeep(biAsianAUsdAcc);
biAsianBUsdDis.productId = "bi-asian-b-usd-dis";
biAsianBUsdDis.storeModule = "biAsianBUsdDis";
biAsianBUsdDis.productName = "B&I Asian B (USD Distributing)";
biAsianBUsdDis.isDefaultShareClass = false;

const biAsianCEurHAcc = cloneDeep(biAsianAUsdAcc);
biAsianCEurHAcc.productId = "bi-asian-c-eur-h-acc";
biAsianCEurHAcc.storeModule = "biAsianCEurHAcc";
biAsianCEurHAcc.productName = "B&I Asian C (EUR-hedged Accumulating)";
biAsianCEurHAcc.isDefaultShareClass = false;

const biAsianDChfHAcc = cloneDeep(biAsianAUsdAcc);
biAsianDChfHAcc.productId = "bi-asian-d-chf-h-acc";
biAsianDChfHAcc.storeModule = "biAsianDChfHAcc";
biAsianDChfHAcc.productName = "B&I Asian (CHF-hedged Accumulating)";
biAsianDChfHAcc.isDefaultShareClass = false;

// ******---------- Model portfolios ----------*****
const bayvkReits1Asia = {
  productId: "bayvk-reits-1-asia",
  storeModule: "bayvkReits1Asia",
  productName: "BAYVK REITS 1 Asia",
  productColor: ORG_COLOR,
  isDefaultShareClass: true,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  allowedRoles: PRIVATE_MANDATES_ALLOWED_ROLES,
  langsForDocuments: ["en", "de"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {},
};

const kzvkReitAsien1 = {
  productId: "kzvk-reit-asien-1",
  storeModule: "kzvkReitAsien1",
  productName: "KZVK REIT Asien 1",
  productColor: ORG_COLOR,
  isDefaultShareClass: true,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  allowedRoles: PRIVATE_MANDATES_ALLOWED_ROLES,
  langsForDocuments: ["en", "de"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {},
};

const kzvkReitNordamerika1 = {
  productId: "kzvk-reit-nordamerika-1",
  storeModule: "kzvkReitNordamerika1",
  productName: "KZVK REIT Nordamerika 1",
  productColor: ORG_COLOR,
  isDefaultShareClass: true,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  allowedRoles: PRIVATE_MANDATES_ALLOWED_ROLES,
  langsForDocuments: ["en", "de"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {},
};

export const BI_CAPITAL_PRODUCTS_DETAILS = [
  biGlobalSUsdAcc,
  biGlobalBUsdDis,
  biGlobalCChfHAcc,
  biGlobalEEurHAcc,
  biGlobalGGBPHDis,
  biAsianAUsdAcc,
  biAsianBUsdDis,
  biAsianCEurHAcc,
  biAsianDChfHAcc,
  bayvkReits1Asia,
  kzvkReitAsien1,
  kzvkReitNordamerika1,
];

export const BI_CAPITAL_PRODUCTS = BI_CAPITAL_PRODUCTS_DETAILS.map(
  (details) => details.storeModule
);
