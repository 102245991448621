import { Role } from "@/acl/roles";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import {
  STANDARD_PERF_STATS_WITH_BENCHMARK,
  STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
} from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];
const INTERNAL_ALLOWED_ROLES = [Role.admin, Role.superAdmin];

const absoluteReturn = {
  productId: "absolute-return",
  storeModule: "absoluteReturn",
  productName: "6T Absolute Return",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const chinaCommonProsperity = {
  productId: "china-common-prosperity",
  storeModule: "chinaCommonProsperity",
  productName: "6T China Common Prosperity",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const globalEquityValueUsd = {
  productId: "global-equity-value-usd",
  storeModule: "globalEquityValueUsd",
  productName: "6T Global Equity Value (USD)",
  productShortName: "6T Global Equity Value",
  productColor: ORG_COLOR,
  productShareClassRoot: "global-equity-value",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const globalEquityValueEur = {
  productId: "global-equity-value-eur",
  storeModule: "globalEquityValueEur",
  productName: "6T Global Equity Value (EUR)",
  productShortName: "6T Global Equity Value",
  productColor: ORG_COLOR,
  productShareClassRoot: "global-equity-value",
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const wfAssetAllocationEur = {
  productId: "white-fleet-a-eur",
  storeModule: "wfAssetAllocationEur",
  productShortName: "WF Asset Allocation Fund",
  productName: "WF Asset Allocation Fund (EUR)",
  productColor: ORG_COLOR,
  productShareClassRoot: "white-fleet",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const wfAssetAllocationChf = {
  productId: "white-fleet-a-h-chf",
  storeModule: "wfAssetAllocationChf",
  productName: "WF Asset Allocation Fund (CHF)",
  productShortName: "WF Asset Allocation Fund",
  productColor: ORG_COLOR,
  productShareClassRoot: "white-fleet",
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const wfAssetAllocationUsd = {
  productId: "white-fleet-a-h-usd",
  storeModule: "wfAssetAllocationUsd",
  productName: "WF Asset Allocation Fund (USD)",
  productShortName: "WF Asset Allocation Fund",
  productColor: ORG_COLOR,
  productShareClassRoot: "white-fleet",
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

export const SIXT_PRODUCTS_DETAILS = [
  absoluteReturn,
  chinaCommonProsperity,
  globalEquityValueUsd,
  globalEquityValueEur,
  wfAssetAllocationEur,
  wfAssetAllocationChf,
  wfAssetAllocationUsd,
];

export const SIXT_PRODUCTS = SIXT_PRODUCTS_DETAILS.map((details) => details.storeModule);
