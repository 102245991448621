// Events name
export const SERVER_ERROR_EVENT = "server_error";
export const SERVER_ERROR_RESPONSE_EVENT = "server_error_response";
export const SERVER_ERROR_REQUEST_EVENT = "server_error_request";
export const SERVER_ERROR_OTHER_EVENT = "server_error_other";
export const CONTACT_EVENT = "contact_request";
export const ACCOUNT_REGISTRATION_EVENT = "new_registration";
export const ACCOUNT_REGISTRATION_2FA_EVENT = "new_registration_2fa";
export const LOGIN_EVENT = "new_login";
export const LOGIN_2FA_EVENT = "new_login_2fa";
export const ACCOUNT_DELETION_EVENT = "delete_account";
export const EDIT_ACCOUNT_EVENT = "edit_account";
export const UPDATE_DATE_EVENT = "update_date";
export const PASSWORD_RESET_EVENT = "password_reset";
export const NOTIFICATIONS_UPDATE_EVENT = "notifications_update";
export const UNDERLYING_SELECTION_EVENT = "underlying_selection";
export const TOOLTIP_DISPLAYED_EVENT = "tooltip_hover";
export const USER_ROLE_CHANGE_EVENT = "user_role_change";
export const MANUAL_EMAIL_VERIFICATION = "manual_email_verification";
export const PRODUCT_CONFIG_CHANGE_EVENT = "product_config_change";
export const PRICE_DATA_EXPORTED_EVENT = "price_data_exported";
export const FACTSHEET_DOWNLOADED_EVENT = "factsheet_downloaded";
export const FACTSHEET_SAVED_IN_DOCUMENTS_EVENT = "factsheet_saved_in_documents";
export const FACTSHEET_SAVED_LAYOUT_EVENT = "factsheet_saved_layout";
export const OVERVIEW_SAVED_LAYOUT_EVENT = "overview_saved_layout";
export const UPDATE_PRICE_DATA_EVENT = "update_price_data";
export const COLLAPSE_CLICKED_EVENT = "collapse_clicked";
export const DOWNLOAD_DOCUMENT_EVENT = "download_document";
export const SELECT_INVESTOR_PROFILE_EVENT = "select_investor_profile";
export const SHARE_CLASS_CHANGED_EVENT = "share_class_changed";
export const ERROR404_EVENT = "error404";
export const ERROR403_EVENT = "error403";
export const REDIRECT_TO_PRISMA = "redirect_to_prisma";
export const BUTTON_CLICKED_EVENT = "button_clicked";
export const TEXT_CLICKED_EVENT = "text_clicked";
export const DOCUMENT_UPLOADED_EVENT = "document_uploaded";
export const DOCUMENT_EDITED_EVENT = "document_edited";
export const DOCUMENT_DELETED_EVENT = "document_deleted";
export const DOCUMENT_ARCHIVED_EVENT = "document_archived";
export const DOCUMENT_UNARCHIVED_EVENT = "document_unarchived";
export const DOCUMENT_REORDERING_EVENT = "document_reordering";
export const TYPED_SEARCH_HOME_EVENT = "typed_search_home";
export const SHOW_MORE_HOME_CLICKED_EVENT = "show_more_home_clicked";
export const TYPED_SEARCH_VERT_NAV_MENU_EVENT = "typed_search_vert_nav_menu";
export const SHOW_MORE_VERT_NAV_MENU_CLICKED_EVENT = "show_more_vert_nav_menu_clicked";
export const PLATFORM_SETTINGS_CHANGED_EVENT = "platform_settings_changed";
export const PRODUCT_FOLLOWED_EVENT = "product_followed";
export const PRODUCT_UNFOLLOWED_EVENT = "product_unfollowed";
export const PRODUCT_FOLLOWED_BY_ADMIN_EVENT = "product_followed_by_admin";
export const PRODUCT_UNFOLLOWED_BY_ADMIN_EVENT = "product_unfollowed_by_admin";
export const FOLLOWERS_EXPORTED_EVENT = "followers_exported";
export const FOLLOWERS_EMAIL_OPENED_EVENT = "followers_email_opened";

// Fixed variable values
export const STATUS_SUCCESS = "success";
export const STATUS_WARNING = "warning";
export const STATUS_FAILURE = "failure";
export const GOOGLE = "Google";
export const MICROSOFT = "Microsoft";
export const EMAIL_AND_PWD = "Email and password";
