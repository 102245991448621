import { Role } from "@/acl/roles";
import { ONE_SWISS_BANK_PRODUCTS_DETAILS } from "@/assets/constants/products/one-swiss-bank";

export default {
  orgName: "ONE swiss bank",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/one-swiss-bank.png"),
  navBarLogoStyle: "width: 100%; height: auto; max-height: 100px",
  allowedLoginMethods: ["Google"],
  hideLogoHomePage: true,
  background: {
    path: require("@/assets/images/background/one-swiss-bank.jpeg"),
    darkRatio: 0.2,
  },
  products: ONE_SWISS_BANK_PRODUCTS_DETAILS,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en", "fr"],
};
