import { Role } from "@/acl/roles";
import { SIXT_PRODUCTS_DETAILS } from "@/assets/constants/products/6t";

export default {
  orgName: "6TAM",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/6t_inline.svg"),
  navBarLogoStyle: "width: 100%; height: auto; max-height: 44px",
  allowedLoginMethods: ["Google"],
  background: {
    path: require("@/assets/images/background/6t.png"),
    darkRatio: 0.3,
  },
  products: SIXT_PRODUCTS_DETAILS,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  oldAlternativeUrlRedirection: [
    {
      oldUrl: "/products/global-equity-value/:section",
      newUrl: "/products/global-equity-value-usd/:section",
    },
  ],
  availableLanguages: ["en"],
  followFeatureActivated: true,
};
