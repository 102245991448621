import { Role } from "@/acl/roles";
import { FORUM_CAPITAL_PRODUCTS_DETAILS } from "@/assets/constants/products/forum-capital";

export default {
  orgName: "Forum Capital Management AG",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/forum-capital.jpeg"),
  navBarLogoStyle: "max-height: 50px; max-width: 100%",
  allowedLoginMethods: ["Google"],
  hideLogoHomePage: true,
  background: {
    path: require("@/assets/images/background/forum-capital.jpeg"),
  },
  products: FORUM_CAPITAL_PRODUCTS_DETAILS,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["de", "en"],
  followFeatureActivated: true,
};
