const getDefaultState = () => {
  return {
    changes: undefined,
    fileErrors: undefined,
  };
};

const state = getDefaultState();

const getters = {
  getChanges: (state) => {
    return state.changes;
  },
  getFileErrors: (state) => {
    return state.fileErrors;
  },
};

const mutations = {
  FETCH_CHANGES_SUCCESS: (state, { data }) => {
    state.changes = data;
  },
  FETCH_FILE_ERRORS_SUCCESS: (state, { data }) => {
    state.fileErrors = data;
  },
  RESET_ALL_STATES(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  setChanges({ commit }, { changes }) {
    commit("FETCH_CHANGES_SUCCESS", {
      data: changes,
    });
  },
  setFileErrors({ commit }, { fileErrors }) {
    commit("FETCH_FILE_ERRORS_SUCCESS", {
      data: fileErrors,
    });
  },
  resetAllStates({ commit }) {
    commit("RESET_ALL_STATES");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
