import "firebase/compat/firestore";

import firebase from "firebase/compat/app";
import { firestoreAction } from "vuexfire";

export default {
  namespaced: true,
  state: {
    preferences: {},
  },
  actions: {
    bindPreferences: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "preferences",
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid)
      );
    }),
    updatePreferences: firestoreAction((_, payload) => {
      const preferences = payload.preferences;

      const userRef = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid);
      userRef.update(preferences);
    }),
  },
  getters: {
    getShowLoggedInProduct: (state) => {
      return state.preferences == null || state.preferences["hide_logged_in_products"] != true;
    },
  },
};
