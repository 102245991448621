// axios
import axios from "axios";

const baseURL = process.env.VUE_APP_API_URL;

const http = axios.create({
  baseURL,
  headers: { Accept: "application/json" },
  timeout: 30000, // 30s
});
export default http;
