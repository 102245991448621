import { Role } from "@/acl/roles";
import { CGE_TECH_CARE_PRODUCTS_DETAILS } from "@/assets/constants/products/cge-tech-care";

export default {
  orgName: "CGE Tech Care",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/cge-tech-care.svg"),
  navBarLogoStyle: "width: 100%; height: auto; max-height: 44px",
  hideLogoHomePage: true,
  allowedLoginMethods: ["Google"],
  background: {
    path: require("@/assets/images/background/cge-tech-care.jpg"),
  },
  products: CGE_TECH_CARE_PRODUCTS_DETAILS,
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en"],
  followFeatureActivated: true,
};
