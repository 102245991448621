import Vue from "vue";

import axios from "@/http/axios";
import { getNested } from "@/store/utils";

const state = {
  keyNumbers: {},
};

const getters = {
  getKeyNumbers: (state) => (productId) => {
    return getNested(state.keyNumbers, productId);
  },
};

const mutations = {
  FETCH_KEY_NUMBERS_SUCCESS: (state, { productIds, data }) => {
    productIds.forEach((productId) => {
      Vue.set(state.keyNumbers, productId, data[productId]);
    });
  },
  FETCH_KEY_NUMBERS_FAILURE: (state, { productIds }) => {
    productIds.forEach((productId) => {
      Vue.set(state.keyNumbers, productId, null);
    });
  },
  RESET_ALL_STATES: (state) => {
    // Reset all the data fetched from the backend.
    state.keyNumbers = {};
  },
};

const actions = {
  async fetchKeyNumbers({ commit }, { productIds, inceptionDates, periodEndDate }) {
    const params = {
      product_ids: productIds,
      inception_dates: inceptionDates.map((dt) => dt ?? ""),
    };
    // Note that we don't expect any 'start date' here because the endpoint
    // knows what 'start date' it should use (i.e., it is not impacted by the front-end).
    if (periodEndDate) {
      params["period_end_date"] = periodEndDate;
    }
    try {
      const response = await axios.get(`/api/products/key-numbers`, {
        params: params,
      });
      commit("FETCH_KEY_NUMBERS_SUCCESS", {
        productIds,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_KEY_NUMBERS_FAILURE", { productIds });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  resetAllStates({ commit }) {
    commit("RESET_ALL_STATES");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
