import components from "./components.js";
import pages from "./pages.js";
import wlps from "./wlps.js";

export default {
  apply: "Appliquer",
  asOf: (ctx) => `au ${ctx.named("date")}`,
  cancel: "Annuler",
  contactUs: "Contactez-nous",
  edit: "Editer",
  error: "Erreur",
  from: "Du",
  ok: "Ok",
  loading: "Chargement...",
  logIn: "S'identifier",
  logOut: "Se déconnecter",
  no: "Non",
  others: "Autres",
  register: "S'inscrire",
  save: "Sauvegarder",
  saving: "Sauvegarde...",
  serverError:
    "Une erreur s'est produite dans notre serveur. Un rapport a été envoyé à notre équipe et nous allons y remédier dès que possible. N'hésitez pas à nous contacter si le problème persiste.",
  success: "Succès",
  to: "Au",
  warning: "Avertissement",
  yes: "Oui",
  investorProfileTypes: {
    institutional: "Investisseur institutionnel",
    professional: "Investisseur professionnel",
    retail: "Investisseur particulier",
  },
  layouts: {
    main: {
      dev: "Dév",
      devTooltip: "Ce produit est en cours de développement",
      admin: {
        listUsers: "Liste des utilisateurs",
        platformSettings: "Paramètres de la plateforme",
        products: "Produits",
        sectionName: "Admin",
        operations: {
          name: "Opérations",
          generateFactsheets: {
            name: "Générer des factsheets",
          },
          bulkComponentsUpdate: {
            name: "Importer des composants génériques",
          },
        },
      },
      help: {
        contact: "Contact",
        faq: "FAQ",
        knowledgeBase: "Base de connaissances",
        sectionName: "Aide",
      },
      products: {
        analysis: "Analyse",
        documents: "Documents",
        factsheet: "Factsheet",
        overview: "Aperçu",
        sectionName: "Produits",
        useCase: "Cas d'utilisation",
      },
    },
    fullPage: {
      backHome: "Retour à l'accueil",
    },
    profileDropDown: {
      settings: "Paramètres",
      dialog: {
        adminAsRegularOnMobile: {
          text: "Les fonctions administratives ne sont pas accessibles sur les appareils mobiles. Pour profiter pleinement de la plateforme, veuillez utiliser un ordinateur de bureau. Les fonctions administratives désactivées comprennent (sans s'y limiter) : l'édition d'éléments dans la vue d'ensemble, l'édition de la fiche d'information, le téléchargement ou la suppression de documents et l'accès à la section d'administration.",
          title: "Ordinateur de bureau nécessaire pour les fonctions d'administration",
        },
      },
    },
    theFooter: {
      allRightReserved: "Tous droits réservés",
      copyright: "Copyright",
      ombudsman: "Médiateur",
      privacyPolicy: "Politique de confidentialité",
      termsOfUse: "Conditions d'utilisation",
    },
    verticalNavMenu: {
      errorLoading: "Erreur lors du chargement du menu",
    },
    verticalNavMenuGroup: {
      search: "Recherche",
      showMore: "Afficher plus",
    },
  },
  perfStats: {
    alpha: "Alpha",
    annualizedReturn: "Rendement annualisé",
    annualizedVolatility: "Volatilité annualisée",
    averageMonthlyReturns: "Rendement mensuel moyen",
    averageNegativeMonthlyReturns: "Moyenne des rendements mensuels négatifs",
    averagePositiveMonthlyReturns: "Moyenne des rendements mensuels positifs",
    beta: "Beta",
    calmarRatio: "Ratio de Calmar",
    correlation: "Corrélation",
    cumulativeReturn: "Rendement cumulé",
    informationRatio: "Ratio d'information",
    kurtosis: "Kurtosis",
    m2RiskAdjustedPerfMonthly: "Mesure M2",
    maxConsecutiveNegativeMonthlyReturns: "Maximum de rendements mensuels négatifs consécutifs",
    maxConsecutivePositiveMonthlyReturns: "Maximum de rendements mensuels positifs consécutifs",
    maxNegativeMonthlyReturn: "Rendement mensuel négatif maximal",
    maxPositiveMonthlyReturn: "Rendement mensuel positif maximal",
    maximumDrawdown: "Drawdown maximal",
    omegaRatio: "Ratio Omega",
    ratioOfNegativeMonthlyReturns: "Ratio des rendements mensuels négatifs",
    ratioOfPositiveMonthlyReturns: "Ratio des rendements mensuels positifs",
    returnToVolatilityRatio: "Ratio rendement/volatilité",
    sharpeRatio: "Ratio de sharpe",
    skewness: "Coefficient d'asymétrie",
    sortinoRatio: "Ratio de Sortino",
    trackingError: "Ecart de suivi",
    treynorRatio: "Ratio de Treynor",
    valueAtRiskOnePercentOneMonth: "Valeur à Risque (VaR) 1% sur 1 mois",
    valueAtRiskFivePercentOneMonth: "Valeur à Risque (VaR) 5% sur 1 mois",
  },
  validations: {
    afterDate: (ctx) =>
      ctx.named("nDaysApart") == 0
        ? `La date doit être postérieure à ${ctx.named("minDate")} (incluse)`
        : `La date doit être au moins ${ctx.named(
            "nDaysApart"
          )} jours après ${ctx.named("minDate")}`,
    beforeDate: (ctx) =>
      ctx.named("nDaysApart") == 0
        ? `La date doit être antérieure à ${ctx.named("maxDate")} (incluse)`
        : `La date doit être au moins ${ctx.named(
            "nDaysApart"
          )} jours avant ${ctx.named("maxDate")}`,
    confirmed: "Le champ ne correspond pas au champ précédent.",
    dateFormat: "La date doit être au format AAAA-MM-JJ.",
    email: "L'email n'est pas valide.",
    maxFileSize: (ctx) =>
      `Le fichier est plus grand que la taille maximale autorisée (${(
        ctx.named("maxSizeBytes") /
        1000 /
        1000
      ).toFixed(0)} Mo).`,
    maxChar: (ctx) => `Le champ ne peut pas comporter plus de ${ctx.named("length")} caractères.`,
    minChar: (ctx) => `Le champ doit contenir au moins ${ctx.named("length")} caractères.`,
    minValue: (ctx) => `La valeur doit être supérieure ou égale à ${ctx.named("min")}.`,
    phoneNumber: "Ce champ n'est pas un numéro de téléphone valide.",
    required: "Ce champ est requis.",
    roleFormat: 'Le nom du rôle ne peut pas contenir le caractère ":".',
    sum: (ctx) =>
      `La somme attendue est ${ctx.named(
        "expectedValue"
      )} (actuelle: ${ctx.named("currentValue")}).`,
  },
  wlps,
  pages,
  components,
};
