export default {
  demo: {
    customSections: {
      useCases: {
        title: "Cas d'usage",
        alpinum: {
          strategyName: "Alpinum",
          title: "Alpinum",
          historicalResults: {
            title: "Résultats historiques",
            biggestDrawdowns: {
              title: (ctx) => `Plus grands drawdowns de ${ctx.named("benchmark")}`,
            },
            drawdowns: {
              title: "Drawdowns",
            },
          },
          metaInfo: {
            title: "Cas d'Usage - Alpinum",
          },
          parameters: {
            runButton: "Exécuter cas d'usage",
            benchmark: "Benchmark",
            title: "Paramètres",
          },
        },
      },
    },
  },
  bcf: {
    redirectButtonNavBarText: "Retour à l'accueil",
  },
  bonhote: {
    redirectButtonNavBarText: "Retour à l'accueil",
  },
  calmgard: {
    nextSubscriptionAndRedemption: {
      nextRedemption: (ctx) =>
        `<b>Les rachats</b> effectués avant le ${ctx.named(
          "date"
        )} à 12h00 CET seront exécutés au dernier VNI de ${ctx.named("month")}.`,
      nextSubscription: (ctx) =>
        `<b>Les souscriptions</b> effectués avant le ${ctx.named(
          "date"
        )} à 12h00 CET seront exécutés au dernier VNI de ${ctx.named("month")}.`,
      title: "Prochaines dates possibles de trading",
    },
  },
  cronos: {
    redirectButtonNavBarText: "Page d'accueil Cronos",
  },
  falgom: {
    taroDiversified: {
      useCase: {
        allocation: {
          equity: "Portefeuille d'actions",
          product: "Allocation à TARO® Diversified",
          title: "Allocation du portefeuille",
        },
        drawdowns: {
          title: "Drawdowns",
          biggestDrawdowns: "Les plus forts drawdowns",
        },
        historicalResults: {
          title: "Résultats historiques",
        },
        metaInfo: {
          title: "Cas d'utilisation - TARO® Diversified",
        },
      },
    },
  },
  mirante: {
    redirectButtonNavBarText: "Page d'accueil MFM",
  },
  ppt: {
    redirectButtonNavBarUrl: "https://ppt.ch/fr/asset-management/",
    redirectButtonNavBarText: "Retour au site principal",
  },
};
