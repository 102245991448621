import { Role } from "@/acl/roles";
import { FINANZLAB_PRODUCTS_DETAILS } from "@/assets/constants/products/finanzlab";

export default {
  orgName: "FINANZLAB",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/finanzlab.png"),
  logoExternalUrl: "https://www.finanzlab.ch",
  allowedLoginMethods: ["Google"],
  background: {
    path: require("@/assets/images/background/finanzlab.png"),
  },
  products: FINANZLAB_PRODUCTS_DETAILS,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en", "fr", "de"],
  followFeatureActivated: true,
};
