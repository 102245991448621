import { i18n } from "@/main.js";

// List of all performance statistics we can add in our different components.
export const PerformanceStatistic = Object.freeze({
  alpha: {
    id: "alpha",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}%`,
    knowledgeBaseTerm: "alpha",
    requireBenchmark: true,
    getName: () => i18n.t("perfStats.alpha"),
  },
  annualizedReturn: {
    id: "annualizedReturn",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}%`,
    knowledgeBaseTerm: "annualized-return",
    requireBenchmark: false,
    getName: () => i18n.t("perfStats.annualizedReturn"),
  },
  annualizedVolatility: {
    id: "annualizedVolatility",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}%`,
    knowledgeBaseTerm: "annualized-volatility",
    requireBenchmark: false,
    getName: () => i18n.t("perfStats.annualizedVolatility"),
  },
  beta: {
    id: "beta",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}f`,
    knowledgeBaseTerm: "beta",
    requireBenchmark: true,
    getName: () => i18n.t("perfStats.beta"),
  },
  correlation: {
    id: "correlation",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}f`,
    knowledgeBaseTerm: "correlation",
    requireBenchmark: true,
    getName: () => i18n.t("perfStats.correlation"),
  },
  cumulativeReturn: {
    id: "cumulativeReturn",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}%`,
    knowledgeBaseTerm: "cumulative-return",
    requireBenchmark: false,
    getName: () => i18n.t("perfStats.cumulativeReturn"),
  },
  informationRatio: {
    id: "informationRatio",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}f`,
    knowledgeBaseTerm: "information-ratio",
    requireBenchmark: true,
    getName: () => i18n.t("perfStats.informationRatio"),
  },
  maximumDrawdown: {
    id: "maximumDrawdown",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}%`,
    knowledgeBaseTerm: "maximum-drawdown",
    requireBenchmark: false,
    getName: () => i18n.t("perfStats.maximumDrawdown"),
  },
  returnToVolatilityRatio: {
    id: "returnToVolatilityRatio",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}f`,
    knowledgeBaseTerm: "return-to-volatility-ratio",
    requireBenchmark: false,
    getName: () => i18n.t("perfStats.returnToVolatilityRatio"),
  },
  sharpeRatio: {
    id: "sharpeRatio",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}f`,
    knowledgeBaseTerm: "sharpe-ratio",
    requireBenchmark: false,
    getName: () => i18n.t("perfStats.sharpeRatio"),
  },
  sortinoRatio: {
    id: "sortinoRatio",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}f`,
    knowledgeBaseTerm: "sortino-ratio",
    requireBenchmark: false,
    getName: () => i18n.t("perfStats.sortinoRatio"),
  },
  calmarRatio: {
    id: "calmarRatio",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}f`,
    knowledgeBaseTerm: "calmar-ratio",
    requireBenchmark: false,
    getName: () => i18n.t("perfStats.calmarRatio"),
  },
  omegaRatio: {
    id: "omegaRatio",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}f`,
    knowledgeBaseTerm: "omega-ratio",
    requireBenchmark: false,
    getName: () => i18n.t("perfStats.omegaRatio"),
  },
  skewness: {
    id: "skewness",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}f`,
    knowledgeBaseTerm: "skewness",
    requireBenchmark: false,
    getName: () => i18n.t("perfStats.skewness"),
  },
  kurtosis: {
    id: "kurtosis",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}f`,
    knowledgeBaseTerm: "kurtosis",
    requireBenchmark: false,
    getName: () => i18n.t("perfStats.kurtosis"),
  },
  trackingError: {
    id: "trackingError",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}%`,
    knowledgeBaseTerm: "tracking-error",
    requireBenchmark: true,
    getName: () => i18n.t("perfStats.trackingError"),
  },
  treynorRatio: {
    id: "treynorRatio",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}f`,
    knowledgeBaseTerm: "treynor-ratio",
    requireBenchmark: true,
    getName: () => i18n.t("perfStats.treynorRatio"),
  },
  m2RiskAdjustedPerfMonthly: {
    id: "m2RiskAdjustedPerfMonthly",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}%`,
    knowledgeBaseTerm: "m2-risk-adjusted-perf",
    requireBenchmark: true,
    getName: () => i18n.t("perfStats.m2RiskAdjustedPerfMonthly"),
  },
  averageMonthlyReturns: {
    id: "averageMonthlyReturns",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}%`,
    requireBenchmark: false,
    // TODO: knowledgeBaseTerm: "average-monthly-returns",
    getName: () => i18n.t("perfStats.averageMonthlyReturns"),
  },
  averagePositiveMonthlyReturns: {
    id: "averagePositiveMonthlyReturns",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}%`,
    requireBenchmark: false,
    // TODO: knowledgeBaseTerm: "average-positive-monthly-returns",
    getName: () => i18n.t("perfStats.averagePositiveMonthlyReturns"),
  },
  averageNegativeMonthlyReturns: {
    id: "averageNegativeMonthlyReturns",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}%`,
    requireBenchmark: false,
    // TODO: knowledgeBaseTerm: "average-negative-monthly-returns",
    getName: () => i18n.t("perfStats.averageNegativeMonthlyReturns"),
  },
  ratioOfPositiveMonthlyReturns: {
    id: "ratioOfPositiveMonthlyReturns",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}%`,
    requireBenchmark: false,
    // TODO: knowledgeBaseTerm: "ratio-of-positive-monthly-returns",
    getName: () => i18n.t("perfStats.ratioOfPositiveMonthlyReturns"),
  },
  ratioOfNegativeMonthlyReturns: {
    id: "ratioOfNegativeMonthlyReturns",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}%`,
    requireBenchmark: false,
    // TODO: knowledgeBaseTerm: "ratio-of-negative-monthly-returns",
    getName: () => i18n.t("perfStats.ratioOfNegativeMonthlyReturns"),
  },
  maxPositiveMonthlyReturn: {
    id: "maxPositiveMonthlyReturn",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}%`,
    requireBenchmark: false,
    // TODO: knowledgeBaseTerm: "max-positive-monthly-return",
    getName: () => i18n.t("perfStats.maxPositiveMonthlyReturn"),
  },
  maxNegativeMonthlyReturn: {
    id: "maxNegativeMonthlyReturn",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}%`,
    requireBenchmark: false,
    // TODO: knowledgeBaseTerm: "max-negative-monthly-return",
    getName: () => i18n.t("perfStats.maxNegativeMonthlyReturn"),
  },
  maxConsecutivePositiveMonthlyReturns: {
    id: "maxConsecutivePositiveMonthlyReturns",
    getFormat: () => ",.0f",
    requireBenchmark: false,
    // TODO: knowledgeBaseTerm: "max-consecutive-positive-monthly-returns",
    getName: () => i18n.t("perfStats.maxConsecutivePositiveMonthlyReturns"),
  },
  maxConsecutiveNegativeMonthlyReturns: {
    id: "maxConsecutiveNegativeMonthlyReturns",
    getFormat: () => ",.0f",
    requireBenchmark: false,
    // TODO: knowledgeBaseTerm: "max-consecutive-negative-monthly-returns",
    getName: () => i18n.t("perfStats.maxConsecutiveNegativeMonthlyReturns"),
  },
  valueAtRiskOnePercentOneMonth: {
    id: "valueAtRiskOnePercentOneMonth",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}%`,
    knowledgeBaseTerm: "value-at-risk",
    requireBenchmark: false,
    getName: () => i18n.t("perfStats.valueAtRiskOnePercentOneMonth"),
  },
  valueAtRiskFivePercentOneMonth: {
    id: "valueAtRiskFivePercentOneMonth",
    getFormat: (numberOfDecimals) => `,.${numberOfDecimals}%`,
    knowledgeBaseTerm: "value-at-risk",
    requireBenchmark: false,
    getName: () => i18n.t("perfStats.valueAtRiskFivePercentOneMonth"),
  },
});

// Standard list of performance statistics that we want to show (with benchmark).
export const STANDARD_PERF_STATS_WITH_BENCHMARK = [
  PerformanceStatistic.cumulativeReturn,
  PerformanceStatistic.annualizedReturn,
  PerformanceStatistic.annualizedVolatility,
  PerformanceStatistic.maximumDrawdown,
  PerformanceStatistic.sharpeRatio,
  PerformanceStatistic.skewness,
  PerformanceStatistic.trackingError,
  PerformanceStatistic.informationRatio,
  PerformanceStatistic.alpha,
  PerformanceStatistic.beta,
];

// Standard list of performance statistics that we want to show (without benchmark).
export const STANDARD_PERF_STATS_WITHOUT_BENCHMARK = [
  PerformanceStatistic.cumulativeReturn,
  PerformanceStatistic.annualizedReturn,
  PerformanceStatistic.annualizedVolatility,
  PerformanceStatistic.maximumDrawdown,
  PerformanceStatistic.sharpeRatio,
  PerformanceStatistic.skewness,
];
