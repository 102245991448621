import moment from "moment";

import { i18n } from "@/main.js";

// List of all zoom buttons we can display and use in our different components.
export const ZoomButton = Object.freeze({
  oneMonth: {
    id: "oneMonth",
    getName: () => i18n.t("components.datePickers.zoomPicker.oneMonth"),
    shouldDisplay: (startDate, endDate) => {
      const oneMonth = moment(endDate).subtract(1, "months");
      return oneMonth.isAfter(moment(startDate));
    },
    getStartDate: (_startDate, endDate) => {
      return moment(endDate).subtract(1, "months").format("YYYY-MM-DD");
    },
  },
  threeMonths: {
    id: "threeMonths",
    getName: () => i18n.t("components.datePickers.zoomPicker.threeMonths"),
    shouldDisplay: (startDate, endDate) => {
      const threeMonths = moment(endDate).subtract(3, "months");
      return threeMonths.isAfter(moment(startDate));
    },
    getStartDate: (_startDate, endDate) => {
      return moment(endDate).subtract(3, "months").format("YYYY-MM-DD");
    },
  },
  sixMonths: {
    id: "sixMonths",
    getName: () => i18n.t("components.datePickers.zoomPicker.sixMonths"),
    shouldDisplay: (startDate, endDate) => {
      const sixMonths = moment(endDate).subtract(6, "months");
      return sixMonths.isAfter(moment(startDate));
    },
    getStartDate: (_startDate, endDate) => {
      return moment(endDate).subtract(6, "months").format("YYYY-MM-DD");
    },
  },
  ytd: {
    id: "ytd",
    getName: () => i18n.t("components.datePickers.zoomPicker.ytd"),
    shouldDisplay: (startDate, endDate) => {
      // Don't put YTD if the last day of the period is not the same as the current
      // date with a gap of 2 months (so that we only show it for products that are still
      // updated).
      if (moment(endDate).isBefore(moment().subtract(2, "months"))) {
        return false;
      }

      const yearToDate = moment(endDate).startOf("year");
      return yearToDate.isAfter(moment(startDate));
    },
    getStartDate: (_startDate, endDate) => {
      return `${moment(endDate).toDate().getFullYear() - 1}-12-31`;
    },
  },
  oneYear: {
    id: "oneYear",
    getName: () => i18n.t("components.datePickers.zoomPicker.oneYear"),
    shouldDisplay: (startDate, endDate) => {
      const oneYear = moment(endDate).subtract(1, "years");
      return oneYear.isAfter(moment(startDate));
    },
    getStartDate: (_startDate, endDate) => {
      return moment(endDate).subtract(1, "years").format("YYYY-MM-DD");
    },
  },
  twoYears: {
    id: "twoYears",
    getName: () => i18n.t("components.datePickers.zoomPicker.twoYears"),
    shouldDisplay: (startDate, endDate) => {
      const twoYears = moment(endDate).subtract(2, "years");
      return twoYears.isAfter(moment(startDate));
    },
    getStartDate: (_startDate, endDate) => {
      return moment(endDate).subtract(2, "years").format("YYYY-MM-DD");
    },
  },
  threeYears: {
    id: "threeYears",
    getName: () => i18n.t("components.datePickers.zoomPicker.threeYears"),
    shouldDisplay: (startDate, endDate) => {
      const threeYears = moment(endDate).subtract(3, "years");
      return threeYears.isAfter(moment(startDate));
    },
    getStartDate: (_startDate, endDate) => {
      return moment(endDate).subtract(3, "years").format("YYYY-MM-DD");
    },
  },
  fiveYears: {
    id: "fiveYears",
    getName: () => i18n.t("components.datePickers.zoomPicker.fiveYears"),
    shouldDisplay: (startDate, endDate) => {
      const fiveYears = moment(endDate).subtract(5, "years");
      return fiveYears.isAfter(moment(startDate));
    },
    getStartDate: (_startDate, endDate) => {
      return moment(endDate).subtract(5, "years").format("YYYY-MM-DD");
    },
  },
  tenYears: {
    id: "tenYears",
    getName: () => i18n.t("components.datePickers.zoomPicker.tenYears"),
    shouldDisplay: (startDate, endDate) => {
      const tenYears = moment(endDate).subtract(10, "years");
      return tenYears.isAfter(moment(startDate));
    },
    getStartDate: (_startDate, endDate) => {
      return moment(endDate).subtract(10, "years").format("YYYY-MM-DD");
    },
  },
  // Used to show all the available data.
  fullPeriod: {
    id: "fullPeriod",
    getName: () => i18n.t("components.datePickers.zoomPicker.fullPeriod"),
    shouldDisplay: (_startDate, _endDate) => {
      return true;
    },
    getStartDate: (startDate, _endDate) => {
      return moment(startDate).format("YYYY-MM-DD");
    },
  },
});

// Standard list of zoom buttons we would display on Analysis and Overview pages.
export const STANDARD_ZOOM_BUTTONS = [
  ZoomButton.oneMonth,
  ZoomButton.sixMonths,
  ZoomButton.ytd,
  ZoomButton.oneYear,
  ZoomButton.fiveYears,
  ZoomButton.fullPeriod,
];
