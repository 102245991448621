import _ from "lodash";

import { Role } from "@/acl/roles";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import {
  STANDARD_PERF_STATS_WITH_BENCHMARK,
  STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
} from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];
const INTERNAL_ALLOWED_ROLES = [Role.admin, Role.superAdmin];

const smallCapStocksDrifter = {
  productId: "small-cap-stocks-drifter",
  storeModule: "smallCapStocksDrifter",
  productName: "Small Cap Stocks Drifter",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  availableLanguages: ["en", "de"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
    defaultLogScale: true,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const pontosValueStrategie = {
  productId: "pontos-value-strategie",
  storeModule: "pontosValueStrategie",
  productName: "Pontos Value Strategie",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  availableLanguages: ["de"],
  langsForDocuments: ["de", "en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const demeterValueStrategie = {
  productId: "demeter-value-strategie",
  storeModule: "demeterValueStrategie",
  productName: "Demeter Value Strategie",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  availableLanguages: ["de"],
  langsForDocuments: ["de", "en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const gaiaValueStrategie = {
  productId: "gaia-value-strategie",
  storeModule: "gaiaValueStrategie",
  productName: "Gaia Value Strategie",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  availableLanguages: ["de"],
  langsForDocuments: ["de", "en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const uranosValueStrategie = {
  productId: "uranos-value-strategie",
  storeModule: "uranosValueStrategie",
  productName: "Uranos Value Strategie",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  availableLanguages: ["de"],
  langsForDocuments: ["de", "en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const titanValueStrategie = {
  productId: "titan-value-strategie",
  storeModule: "titanValueStrategie",
  productName: "Titan Value Strategie",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  availableLanguages: ["de"],
  langsForDocuments: ["de", "en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const mdotmEquityEuropeCertificate = {
  productId: "mdotm-equity-europe-certificate",
  storeModule: "mdotmEquityEuropeCertificate",
  productName: "MDOTM Eq. Europe Strategy",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  availableLanguages: ["en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const volumeHedgeCertificate = {
  productId: "volume-hedge-certificate",
  storeModule: "volumeHedgeCertificate",
  productName: "Volume Hedge Certificate",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  availableLanguages: ["en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const hachikoUsEquity = {
  productId: "hachiko-us-equity",
  storeModule: "hachikoUsEquity",
  productName: "Hachiko US Equity",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  availableLanguages: ["en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const microStrategies = {
  productId: "micro-strategies",
  storeModule: "microStrategies",
  productName: "Micro Strategies",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  availableLanguages: ["en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const alternativeOption = {
  productId: "alternative-option",
  storeModule: "alternativeOption",
  productName: "Alternative Option",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  availableLanguages: ["en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const tigerValueStrategy = {
  productId: "tiger-value-strategy",
  storeModule: "tigerValueStrategy",
  productName: "Tiger Value Strategy 1.5 AMC",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  availableLanguages: ["en"],
  langsForDocuments: ["en", "de"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const valueHoldingsDividendenFund = {
  productId: "value-holdings-dividenden-fund",
  storeModule: "valueHoldingsDividendenFund",
  productName: "Value-Holdings Dividenden Fund",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  availableLanguages: ["en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

// ******---------- TL Nachhaltigkeitsfonds ----------*****
const tlNachhaltigkeitsfondsBrascri = {
  productId: "tl-nachhaltigkeitsfonds-brascri",
  storeModule: "tlNachhaltigkeitsfondsBrascri",
  productName: "TL Nachhaltigkeitsfonds BRASCRI",
  productShortName: "TL Nachhaltigkeitsfonds",
  productColor: ORG_COLOR,
  productShareClassRoot: "tl-nachhaltigkeitsfonds",
  isDefaultShareClass: true,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  availableLanguages: ["en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {},
};

const tlNachhaltigkeitsfondsHilfswerke = _.cloneDeep(tlNachhaltigkeitsfondsBrascri);
tlNachhaltigkeitsfondsHilfswerke.productId = "tl-nachhaltigkeitsfonds-hilfswerke";
tlNachhaltigkeitsfondsHilfswerke.storeModule = "tlNachhaltigkeitsfondsHilfswerke";
tlNachhaltigkeitsfondsHilfswerke.productName = "TL Nachhaltigkeitsfonds HILFSWERKE";
tlNachhaltigkeitsfondsHilfswerke.isDefaultShareClass = false;

// ******---------- PI Global Value Fund ----------*****
const piGlobalValueFundEurP = {
  productId: "pi-global-value-fund-eur-p",
  storeModule: "piGlobalValueFundEurP",
  productName: "PI Global Value Fund EUR P",
  productShortName: "PI Global Value Fund",
  productColor: ORG_COLOR,
  productShareClassRoot: "pi-global-value-fund",
  isDefaultShareClass: true,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  availableLanguages: ["en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {},
};

const piGlobalValueFundEurI = _.cloneDeep(piGlobalValueFundEurP);
piGlobalValueFundEurI.productId = "pi-global-value-fund-eur-i";
piGlobalValueFundEurI.storeModule = "piGlobalValueFundEurI";
piGlobalValueFundEurI.productName = "PI Global Value Fund EUR I";
piGlobalValueFundEurI.isDefaultShareClass = false;

const piGlobalValueFundChfP = _.cloneDeep(piGlobalValueFundEurP);
piGlobalValueFundChfP.productId = "pi-global-value-fund-chf-p";
piGlobalValueFundChfP.storeModule = "piGlobalValueFundChfP";
piGlobalValueFundChfP.productName = "PI Global Value Fund CHF P";
piGlobalValueFundChfP.isDefaultShareClass = false;

const piGlobalValueFundChfI = _.cloneDeep(piGlobalValueFundEurP);
piGlobalValueFundChfI.productId = "pi-global-value-fund-chf-i";
piGlobalValueFundChfI.storeModule = "piGlobalValueFundChfI";
piGlobalValueFundChfI.productName = "PI Global Value Fund CHF I";
piGlobalValueFundChfI.isDefaultShareClass = false;

const unconstrainedIncomeOpportunities = {
  productId: "unconstrained-income-opportunities",
  storeModule: "unconstrainedIncomeOpportunities",
  productName: "Unconstrained Income Opportunities",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  availableLanguages: ["en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const spWorldEquities = {
  productId: "sp-world-equities",
  storeModule: "spWorldEquities",
  productName: "SP Equity World LV",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  availableLanguages: ["en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const lShortTermTradingUsaAmc = {
  productId: "l-short-term-trading-usa-amc",
  storeModule: "lShortTermTradingUsaAmc",
  productName: "L Short Term Trading USA AMC",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  availableLanguages: ["en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

export const BANCA_CREDINVEST_PRODUCTS_DETAILS = [
  smallCapStocksDrifter,
  pontosValueStrategie,
  demeterValueStrategie,
  gaiaValueStrategie,
  uranosValueStrategie,
  titanValueStrategie,
  mdotmEquityEuropeCertificate,
  volumeHedgeCertificate,
  hachikoUsEquity,
  microStrategies,
  alternativeOption,
  tigerValueStrategy,
  valueHoldingsDividendenFund,
  tlNachhaltigkeitsfondsBrascri,
  tlNachhaltigkeitsfondsHilfswerke,
  piGlobalValueFundEurP,
  piGlobalValueFundEurI,
  piGlobalValueFundChfP,
  piGlobalValueFundChfI,
  unconstrainedIncomeOpportunities,
  spWorldEquities,
  lShortTermTradingUsaAmc,
];

export const BANCA_CREDINVEST_PRODUCTS = BANCA_CREDINVEST_PRODUCTS_DETAILS.map(
  (details) => details.storeModule
);
