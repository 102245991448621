export const Role = Object.freeze({
  // Anyone on the platform.
  guest: "guest",
  // Any user that is logged in.
  insight: "insight",
  // Any user that should be allowed to view the content of the platform (if available).
  viewer: "viewer",
  // Specific to Alquant. Users that should be allowed to access the content about Alvola CHF.
  alvolaCHF: "alvolaCHF",
  // Specific to Alquant. Users that should be allowed to access the content about LSTS.
  lifeSciencesToolsAndServices: "lifeSciencesToolsAndServices",
  // Any user that is internal to the company, but is not an admin.
  employee: "employee",
  // Any user that is an overall admin of the platform (can change everything on the platform).
  admin: "admin",
  // Only given to Alquant developers of the platform; like admin but cannot be removed by admins.
  superAdmin: "superAdmin",
});
