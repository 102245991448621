import { Role } from "@/acl/roles";
import { MSM_PRODUCTS_DETAILS } from "@/assets/constants/products/msm";

export default {
  orgName: "MSM Investment Advisors S.A.",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/msm.svg"),
  navBarLogoStyle: "width: 100%; height: auto; max-height: 44px",
  allowedLoginMethods: ["Google"],
  hideLogoHomePage: true,
  background: {
    path: require("@/assets/images/background/msm.png"),
  },
  products: MSM_PRODUCTS_DETAILS,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en"],
  followFeatureActivated: true,
};
