import axios from "@/http/axios";

const getDefaultState = () => {
  return {
    selectedProducts: undefined,
    selectedLangs: undefined,
    lastFactsheetDate: undefined,
    needLogin: undefined,
    isLastFactsheet: undefined,
  };
};

const state = getDefaultState();

const getters = {
  getSelectedProducts: (state) => {
    return state.selectedProducts;
  },
  getSelectedLangs: (state) => {
    return state.selectedLangs;
  },
  getLastFactsheetDate: (state) => {
    return state.lastFactsheetDate;
  },
  getNeedLogin: (state) => {
    return state.needLogin;
  },
  getIsLastFactsheet: (state) => {
    return state.isLastFactsheet;
  },
};

const mutations = {
  FETCH_SELECTED_PRODUCTS_SUCCESS: (state, { data }) => {
    state.selectedProducts = data;
  },
  FETCH_SELECTED_LANGS_SUCCESS: (state, { data }) => {
    state.selectedLangs = data;
  },
  FETCH_LAST_FACTSHEET_DATE_SUCCESS: (state, { data }) => {
    state.lastFactsheetDate = data;
  },
  FETCH_NEED_LOGIN_SUCCESS: (state, { data }) => {
    state.needLogin = data;
  },
  FETCH_IS_LAST_FACTSHEET_SUCCESS: (state, { data }) => {
    state.isLastFactsheet = data;
  },
  RESET_ALL_STATES(state) {
    // Reset all the data fetched from the backend.
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  setSelectedProducts({ commit }, { selectedProducts }) {
    commit("FETCH_SELECTED_PRODUCTS_SUCCESS", {
      data: selectedProducts,
    });
  },
  setSelectedLangs({ commit }, { selectedLangs }) {
    commit("FETCH_SELECTED_LANGS_SUCCESS", {
      data: selectedLangs,
    });
  },
  setLastFactsheetDate({ commit }, { lastFactsheetDate }) {
    commit("FETCH_LAST_FACTSHEET_DATE_SUCCESS", {
      data: lastFactsheetDate,
    });
  },
  setNeedLogin({ commit }, { needLogin }) {
    commit("FETCH_NEED_LOGIN_SUCCESS", {
      data: needLogin,
    });
  },
  setIsLastFactsheet({ commit }, { isLastFactsheet }) {
    commit("FETCH_IS_LAST_FACTSHEET_SUCCESS", {
      data: isLastFactsheet,
    });
  },
  resetAllStates({ commit }) {
    commit("RESET_ALL_STATES");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
