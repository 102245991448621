import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import {
  STANDARD_PERF_STATS_WITH_BENCHMARK,
  STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
} from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];

const preciousCapitalGlobalMiningMetalsFundClass1Chf = {
  productId: "precious-capital-global-mining-metals-fund-class-1-chf",
  storeModule: "preciousCapitalGlobalMiningMetalsFundClass1Chf",
  productName: "Precious Capital Global Mining and Metals Fund Class 1 CHF",
  productColor: ORG_COLOR,
  showLastPrice: true,
  langsForDocuments: ["en", "de"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const surtseyMetalsMiningFundClassSChf = {
  productId: "surtsey-metals-mining-fund-class-s-chf",
  storeModule: "surtseyMetalsMiningFundClassSChf",
  productName: "Surtsey Metals & Mining Fund",
  productColor: ORG_COLOR,
  showLastPrice: true,
  langsForDocuments: ["en", "de"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const systematicGlobalDividendFundEurI = {
  productId: "systematic-global-dividend-fund-eur-i",
  storeModule: "systematicGlobalDividendFundEurI",
  productName: "Systematic Global Dividends Fund",
  productColor: ORG_COLOR,
  showLastPrice: true,
  langsForDocuments: ["en", "de"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

// Fund change its name on June 30th 2024, hence the discrepancy between
// the 'productName' and the rest.
const ssiAmericanStrategicLeadersFundUsdI = {
  productId: "ssi-american-strategic-leaders-fund-usd-i",
  storeModule: "ssiAmericanStrategicLeadersFundUsdI",
  productName: "US Innovation Champions Fund",
  productColor: ORG_COLOR,
  showLastPrice: true,
  langsForDocuments: ["en", "de"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const ssiGlobalStrategicOpportunityFundEurI = {
  productId: "ssi-global-strategic-opportunity-fund-eur-i",
  storeModule: "ssiGlobalStrategicOpportunityFundEurI",
  productName: "SSI Global Strategic Opportunity Fund",
  productColor: ORG_COLOR,
  showLastPrice: true,
  langsForDocuments: ["en", "de"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

export const SSI_PRODUCTS_DETAILS = [
  preciousCapitalGlobalMiningMetalsFundClass1Chf,
  surtseyMetalsMiningFundClassSChf,
  systematicGlobalDividendFundEurI,
  ssiAmericanStrategicLeadersFundUsdI,
  ssiGlobalStrategicOpportunityFundEurI,
];

export const SSI_PRODUCTS = SSI_PRODUCTS_DETAILS.map((details) => details.storeModule);
