/* File containing constants related to the Investor Profile (IP). */

import { i18n } from "@/main.js";

// Keys stored in localStorage.
export const IP_TYPE_KEY = "investorProfileName";
export const IP_COUNTRY_KEY = "investorProfileCountry";

/*
  Investor types
*/
export const IP_RETAIL_TYPE_ID = "retail";
export const IP_PROFESSIONAL_TYPE_ID = "professional";
export const IP_INSTITUTIONAL_TYPE_ID = "institutional";
export const GET_IP_ALL_TYPES = () => {
  return [
    { value: IP_RETAIL_TYPE_ID, label: i18n.t("investorProfileTypes.retail") },
    {
      value: IP_PROFESSIONAL_TYPE_ID,
      label: i18n.t("investorProfileTypes.professional"),
    },
    {
      value: IP_INSTITUTIONAL_TYPE_ID,
      label: i18n.t("investorProfileTypes.institutional"),
    },
  ];
};

/*
  Countries
*/
import * as countries from "i18n-iso-countries";
// Simpler to load them by default for now, this list won't change
// a lot over time. If we find ourselves with a lot of them at some
// point, we would need to think of a dynamic way to load them, but
// this has some serious code modification down the line.
import en from "i18n-iso-countries/langs/en.json";
countries.registerLocale(en);
import de from "i18n-iso-countries/langs/de.json";
countries.registerLocale(de);
import fr from "i18n-iso-countries/langs/fr.json";
countries.registerLocale(fr);
import it from "i18n-iso-countries/langs/it.json";
countries.registerLocale(it);
import pt from "i18n-iso-countries/langs/pt.json";
countries.registerLocale(pt);

export const GET_IP_ALL_COUNTRIES = (lang) => {
  const countryCodeToName = countries.getNames(lang);
  // Construct the countries collection to work well with v-select.
  const frontCodes = [
    IP_SWITZERLAND_CODE,
    "LU",
    "AT",
    "BE",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "DE",
    "GR",
    "HU",
    "IE",
    "IT",
    "LV",
    "LT",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
    "GB",
    "HK",
    "SG",
  ];
  // For the sorting of the backCodes, we will always
  // use the same language. It is then easier to compare
  // list of countries, whatever the language of the user.
  const enCountryCodeToName = countries.getNames("en");
  const backCodes = Object.keys(enCountryCodeToName)
    .sort((code1, code2) => enCountryCodeToName[code1].localeCompare(enCountryCodeToName[code2]))
    .filter((code) => !frontCodes.includes(code));
  const orderedCodes = [...frontCodes, ...backCodes];
  return orderedCodes.map((code) => ({
    value: code,
    label: countryCodeToName[code],
  }));
};
export const IP_SWITZERLAND_CODE = "CH";

// All of this happens before we set up the vuex store with the IP_COUNTRY_KEY
// and IP_TYPE_KEY.
// Legacy localStorage update to replace investor profile type and country
// to code instead of localized values.
const previousInvestorProfileCountry = localStorage.getItem(IP_COUNTRY_KEY);
const countryCodeToName = countries.getNames("en");
if (previousInvestorProfileCountry && previousInvestorProfileCountry.length > 2) {
  const newCountryCode = Object.keys(countryCodeToName).find(
    (key) => countryCodeToName[key] == previousInvestorProfileCountry
  );
  if (newCountryCode) {
    localStorage.setItem(IP_COUNTRY_KEY, newCountryCode);
  } else {
    // If for some reason we can't find it, we delete it entirely
    // and we redirect user to investor profile page.
    localStorage.removeItem(IP_COUNTRY_KEY);
  }
}

const previousInvestorProfileType = localStorage.getItem(IP_TYPE_KEY);
const legacyMapping = {
  "Retail investor": IP_RETAIL_TYPE_ID,
  "Professional investor": IP_PROFESSIONAL_TYPE_ID,
  "Institutional investor": IP_INSTITUTIONAL_TYPE_ID,
};
if (
  previousInvestorProfileType &&
  !Object.values(legacyMapping).includes(previousInvestorProfileType)
) {
  if (legacyMapping[previousInvestorProfileType]) {
    localStorage.setItem(IP_TYPE_KEY, legacyMapping[previousInvestorProfileType]);
  } else {
    // If for some reason we can't find it, we delete it entirely
    // and we redirect user to investor profile page.
    localStorage.removeItem(IP_TYPE_KEY);
  }
}
