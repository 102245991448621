import moment from "moment";

import { LOCALE_KEY } from "@/assets/constants/global.js";
import config from "@/config";
import { i18n } from "@/main.js";

export function buildPathWithLang(url) {
  if (config.langInUrl) {
    return `/:lang${url}`;
  }

  return url;
}

export function beforeEnterChangeLocale(to, from, next) {
  if (!config.langInUrl) {
    return next();
  }

  // Retrieve the current locale set in the URL, or get the first available language.
  const locale = to.params.lang ?? config.availableLanguages[0];

  // Check if the locale the user is trying to access is authorised.
  if (!config.availableLanguages.includes(locale)) {
    return next({
      name: "page-error-404",
      params: {
        lang: config.availableLanguages[0],
      },
    });
  }

  // Changing the language from the URL (either manually or with a link) is possible this way.
  i18n.locale = locale;
  moment.locale(locale);
  localStorage.setItem(LOCALE_KEY, locale);
  return next();
}
