import { Role } from "@/acl/roles";
import { BCJ_PRODUCTS_DETAILS } from "@/assets/constants/products/bcj";

export default {
  orgName: "BCJ",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/bcj.svg"),
  navBarLogoStyle: "width: 100%; height: auto; max-height: 100px",
  allowedLoginMethods: ["Google"],
  hideLogoHomePage: true,
  background: {
    path: require("@/assets/images/background/bcj.jpg"),
  },
  products: BCJ_PRODUCTS_DETAILS,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["fr"],
  colorScheme: ["#FF000D", "#96000D", "#E3E3E3", "#A0A0A0", "#464646", "#000000"],
};
