import { Role } from "@/acl/roles";
import { UTI_PRODUCTS_DETAILS } from "@/assets/constants/products/uti";

export default {
  orgName: "ÜTI",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/uti-black.svg"),
  navBarLogoStyle: "width: 100%; height: auto; max-height: 44px",
  allowedLoginMethods: ["Google"],
  hideLogoHomePage: true,
  hideCopyrightFooter: true,
  background: {
    path: require("@/assets/images/background/uti.jpg"),
  },
  products: UTI_PRODUCTS_DETAILS,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en"],
  followFeatureActivated: true,
};
