import { cloneDeep } from "lodash";

import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import {
  STANDARD_PERF_STATS_WITH_BENCHMARK,
  STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
} from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];

// ******---------- Bonhôte Selection - Global Bonds ESG ----------*****
const globalBondsIa = {
  productId: "global-bonds-ia",
  storeModule: "globalBondsIa",
  productName: "Bonhôte Selection - Global Bonds ESG IA",
  productShortName: "Bonhôte Selection - Global Bonds ESG",
  productColor: ORG_COLOR,
  productShareClassRoot: "global-bonds",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const globalBondsId = cloneDeep(globalBondsIa);
globalBondsId.productId = "global-bonds-id";
globalBondsId.storeModule = "globalBondsId";
globalBondsId.productName = "Bonhôte Selection - Global Bonds ESG ID";
globalBondsId.isDefaultShareClass = false;

const globalBondsUhid = cloneDeep(globalBondsIa);
globalBondsUhid.productId = "global-bonds-uhid";
globalBondsUhid.storeModule = "globalBondsUhid";
globalBondsUhid.productName = "Bonhôte Selection - Global Bonds ESG UHID";
globalBondsUhid.isDefaultShareClass = false;

// ******---------- Bonhôte Selection - Global Emerging Multi-Fonds (CHF) ----------*****
const globalEmerging = {
  productId: "global-emerging",
  storeModule: "globalEmerging",
  productName: "Bonhôte Selection - Global Emerging Multi-Fonds (CHF)",
  productColor: ORG_COLOR,
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

// ******---------- Bonhôte Strategies - Megatrend Equity ESG ----------*****
const megatrendI = {
  productId: "megatrend-i",
  storeModule: "megatrendI",
  productName: "Bonhôte Strategies - Megatrend Equity ESG I",
  productShortName: "Bonhôte Strategies - Megatrend Equity ESG",
  productColor: ORG_COLOR,
  productShareClassRoot: "Megatrend Equity ESG",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const megatrendIh = cloneDeep(megatrendI);
megatrendIh.productId = "megatrend-ih";
megatrendIh.storeModule = "megatrendIh";
megatrendIh.productName = "Bonhôte Strategies - Megatrend Equity ESG IH";
megatrendIh.isDefaultShareClass = false;

const megatrendIuh = cloneDeep(megatrendI);
megatrendIuh.productId = "megatrend-iuh";
megatrendIuh.storeModule = "megatrendIuh";
megatrendIuh.productName = "Bonhôte Strategies - Megatrend Equity ESG IUH";
megatrendIuh.isDefaultShareClass = false;

// ******---------- Bonhôte Strategies - Balanced (CHF) ----------*****
const balanced = {
  productId: "balanced",
  storeModule: "balanced",
  productName: "Bonhôte Strategies - Balanced (CHF)",
  productColor: ORG_COLOR,
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

// ******---------- Convergence Lux – Bonhôte Impact Fund ----------*****
const impactA = {
  productId: "impact-a",
  storeModule: "impactA",
  productName: "Convergence Lux – Bonhôte Impact Fund A-CHF",
  productShortName: "Convergence Lux – Bonhôte Impact Fund",
  productColor: ORG_COLOR,
  productShareClassRoot: "impact",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const impactIChf = cloneDeep(impactA);
impactIChf.productId = "impact-i-chf";
impactIChf.storeModule = "impactIChf";
impactIChf.productName = "Convergence Lux – Bonhôte Impact Fund I-CHF";
impactIChf.isDefaultShareClass = false;

const impactIEur = cloneDeep(impactA);
impactIEur.productId = "impact-i-eur";
impactIEur.storeModule = "impactIEur";
impactIEur.productName = "Convergence Lux – Bonhôte Impact Fund I-EUR";
impactIEur.isDefaultShareClass = false;

// ******---------- Convergence Capital – Strategic Credit ----------*****
const strategicCreditChf = {
  productId: "strategic-credit-chf",
  storeModule: "strategicCredit",
  productName: "Convergence Capital – Strategic Credit CHF",
  productShortName: "Convergence Capital – Strategic Credit",
  productColor: ORG_COLOR,
  productShareClassRoot: "strategic-credit",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.monthly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const strategicCreditUsd = cloneDeep(strategicCreditChf);
strategicCreditUsd.productId = "strategic-credit-usd";
strategicCreditUsd.storeModule = "strategicCreditUsd";
strategicCreditUsd.productName = "Convergence Capital – Strategic Credit USD";
strategicCreditUsd.isDefaultShareClass = false;

const strategicCreditEur = cloneDeep(strategicCreditChf);
strategicCreditEur.productId = "strategic-credit-eur";
strategicCreditEur.storeModule = "strategicCreditEur";
strategicCreditEur.productName = "Convergence Capital – Strategic Credit EUR";
strategicCreditEur.isDefaultShareClass = false;

// ******---------- Bonhôte-Immobilier SICAV - BIM ----------*****
const bim = {
  productId: "bim",
  storeModule: "bim",
  productName: "Bonhôte-Immobilier SICAV - BIM",
  productColor: ORG_COLOR,
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

// ******---------- Bonhôte Alternative Multi-Performance ----------*****
const alternativeChf = {
  productId: "alternative-chf",
  storeModule: "alternativeChf",
  productName: "Bonhôte Alternative Multi-Performance (CHF)",
  productShortName: "Bonhôte Alternative Multi-Performance",
  productColor: ORG_COLOR,
  productShareClassRoot: "alternative",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.monthly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const alternativeEur = cloneDeep(alternativeChf);
alternativeEur.productId = "alternative-eur";
alternativeEur.storeModule = "alternativeEur";
alternativeEur.productName = "Bonhôte Alternative Multi-Performance (EUR)";
alternativeEur.isDefaultShareClass = false;

const alternativeUsd = cloneDeep(alternativeChf);
alternativeUsd.productId = "alternative-usd";
alternativeUsd.storeModule = "alternativeUsd";
alternativeUsd.productName = "Bonhôte Alternative Multi-Performance (USD)";
alternativeUsd.isDefaultShareClass = false;

// ******---------- Convergence Lux – ZO China Value Fund ----------*****
const zoChinaAChf = {
  productId: "zo-china-a-chf",
  storeModule: "zoChinaAChf",
  productName: "Convergence Lux – ZO China Value Fund A-CHF",
  productShortName: "Convergence Lux – ZO China Value Fund",
  productColor: ORG_COLOR,
  productShareClassRoot: "zo-china",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const zoChinaAEur = cloneDeep(zoChinaAChf);
zoChinaAEur.productId = "zo-china-a-eur";
zoChinaAEur.storeModule = "zoChinaAEur";
zoChinaAEur.productName = "Convergence Lux – ZO China Value Fund A-EUR";
zoChinaAEur.isDefaultShareClass = false;

const zoChinaAUsd = cloneDeep(zoChinaAChf);
zoChinaAUsd.productId = "zo-china-a-usd";
zoChinaAUsd.storeModule = "zoChinaAUsd";
zoChinaAUsd.productName = "Convergence Lux – ZO China Value Fund A-USD";
zoChinaAUsd.isDefaultShareClass = false;

const zoChinaIChf = cloneDeep(zoChinaAChf);
zoChinaIChf.productId = "zo-china-i-chf";
zoChinaIChf.storeModule = "zoChinaIChf";
zoChinaIChf.productName = "Convergence Lux – ZO China Value Fund I-CHF";
zoChinaIChf.isDefaultShareClass = false;

const zoChinaIEur = cloneDeep(zoChinaAChf);
zoChinaIEur.productId = "zo-china-i-eur";
zoChinaIEur.storeModule = "zoChinaIEur";
zoChinaIEur.productName = "Convergence Lux – ZO China Value Fund I-EUR";
zoChinaIEur.isDefaultShareClass = false;

const zoChinaIUsd = cloneDeep(zoChinaAChf);
zoChinaIUsd.productId = "zo-china-i-usd";
zoChinaIUsd.storeModule = "zoChinaIUsd";
zoChinaIUsd.productName = "Convergence Lux – ZO China Value Fund I-USD";
zoChinaIUsd.isDefaultShareClass = false;

export const BONHOTE_PRODUCTS_DETAILS = [
  globalBondsIa,
  globalBondsId,
  globalBondsUhid,
  globalEmerging,
  megatrendI,
  megatrendIh,
  megatrendIuh,
  balanced,
  impactA,
  impactIChf,
  impactIEur,
  strategicCreditChf,
  strategicCreditUsd,
  strategicCreditEur,
  bim,
  alternativeChf,
  alternativeEur,
  alternativeUsd,
  zoChinaAChf,
  zoChinaAEur,
  zoChinaAUsd,
  zoChinaIChf,
  zoChinaIEur,
  zoChinaIUsd,
];

export const BONHOTE_PRODUCTS = BONHOTE_PRODUCTS_DETAILS.map((details) => details.storeModule);
