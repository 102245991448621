import _ from "lodash";

import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { STANDARD_PERF_STATS_WITHOUT_BENCHMARK } from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];

// ******---------- Chameleon Sustainable Global Convertible Bond Fund----------*****
const chameleonSustainableGlobalConvertibleBondFundChfI = {
  productId: "chameleon-sustainable-global-convertible-bond-fund-chf-i",
  storeModule: "chameleonSustainableGlobalConvertibleBondFundChfI",
  productName: "Sustainable Global Convertible Bond Fund CHF I",
  productShortName: "Sustainable Global Convertible Bond Fund",
  productColor: ORG_COLOR,
  productShareClassRoot: "chameleon-sustainable-global-convertible-bond-fund",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.outperformance,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const chameleonSustainableGlobalConvertibleBondFundChfI2 = _.cloneDeep(
  chameleonSustainableGlobalConvertibleBondFundChfI
);
chameleonSustainableGlobalConvertibleBondFundChfI2.productId =
  "chameleon-sustainable-global-convertible-bond-fund-chf-i2";
chameleonSustainableGlobalConvertibleBondFundChfI2.storeModule =
  "chameleonSustainableGlobalConvertibleBondFundChfI2";
chameleonSustainableGlobalConvertibleBondFundChfI2.productName =
  "Sustainable Global Convertible Bond Fund CHF I2";
chameleonSustainableGlobalConvertibleBondFundChfI2.isDefaultShareClass = false;

const chameleonSustainableGlobalConvertibleBondFundChfP = _.cloneDeep(
  chameleonSustainableGlobalConvertibleBondFundChfI
);
chameleonSustainableGlobalConvertibleBondFundChfP.productId =
  "chameleon-sustainable-global-convertible-bond-fund-chf-p";
chameleonSustainableGlobalConvertibleBondFundChfP.storeModule =
  "chameleonSustainableGlobalConvertibleBondFundChfP";
chameleonSustainableGlobalConvertibleBondFundChfP.productName =
  "Sustainable Global Convertible Bond Fund CHF P";
chameleonSustainableGlobalConvertibleBondFundChfP.isDefaultShareClass = false;

const chameleonSustainableGlobalConvertibleBondFundEurI = _.cloneDeep(
  chameleonSustainableGlobalConvertibleBondFundChfI
);
chameleonSustainableGlobalConvertibleBondFundEurI.productId =
  "chameleon-sustainable-global-convertible-bond-fund-eur-i";
chameleonSustainableGlobalConvertibleBondFundEurI.storeModule =
  "chameleonSustainableGlobalConvertibleBondFundEurI";
chameleonSustainableGlobalConvertibleBondFundEurI.productName =
  "Sustainable Global Convertible Bond Fund EUR I";
chameleonSustainableGlobalConvertibleBondFundEurI.isDefaultShareClass = false;

const chameleonSustainableGlobalConvertibleBondFundEurP = _.cloneDeep(
  chameleonSustainableGlobalConvertibleBondFundChfI
);
chameleonSustainableGlobalConvertibleBondFundEurP.productId =
  "chameleon-sustainable-global-convertible-bond-fund-eur-p";
chameleonSustainableGlobalConvertibleBondFundEurP.storeModule =
  "chameleonSustainableGlobalConvertibleBondFundEurP";
chameleonSustainableGlobalConvertibleBondFundEurP.productName =
  "Sustainable Global Convertible Bond Fund EUR P";
chameleonSustainableGlobalConvertibleBondFundEurP.isDefaultShareClass = false;

export const CHAMELEON_PRODUCTS_DETAILS = [
  chameleonSustainableGlobalConvertibleBondFundChfI,
  chameleonSustainableGlobalConvertibleBondFundChfI2,
  chameleonSustainableGlobalConvertibleBondFundChfP,
  chameleonSustainableGlobalConvertibleBondFundEurI,
  chameleonSustainableGlobalConvertibleBondFundEurP,
];

export const CHAMELEON_PRODUCTS = CHAMELEON_PRODUCTS_DETAILS.map((details) => details.storeModule);
