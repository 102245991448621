import { Role } from "@/acl/roles";
import { OPENFUNDS_PRODUCTS_DETAILS } from "@/assets/constants/products/openfunds";

export default {
  orgName: "OpenFunds",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/openfunds.png"),
  allowedLoginMethods: ["Google"],
  background: {
    path: require("@/assets/images/background/openfunds.jpeg"),
    darkRatio: 0.5,
  },
  products: OPENFUNDS_PRODUCTS_DETAILS,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en"],
  followFeatureActivated: true,
};
